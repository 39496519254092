import { Suspense } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ReactComponent as Arrow } from '../svg/Arrow.svg';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';
import { Description } from '../ui/text';
import ThyrOxyLogo from '../science/ThyrOxyLogo';

const ClinicalTrialWrapper = styled.section`
  display: grid;
  margin: 0 0 0 0;
  grid-template-areas: "main";
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  background-color: ${colors.primary.v800};
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-area: main;
  grid-template-areas: "logo" "line" "heading" "text" "cta";
  grid-template-columns: 1fr;
  grid-template-rows: 20em 2em 10em auto 10em;
  margin: 10em 10em 10em 10em;
  max-width: 900px;
  justify-self: center;

  @media (max-width: 1000px) {
    margin: 10em 5em 10em 5em;
  }

  @media (max-width: 750px) {
    margin: 10em 2em 10em 2em;
  }

  @media (max-width: 550px) {
    margin: 10em 1em 10em 1em;
    grid-template-rows: 12em 2em 10em auto 10em;
  }
`;

const LogoWrapper = styled.div`
  grid-area: logo;
  justify-self: center;

  @media (max-width: 400px) {
    width: 15em;
  }
`;

const Heading = styled.h1`
  grid-area: heading;
  color: ${colors.primary.v100};
  font-size: 2em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  margin: 0 0 0 -8rem;

  @media (max-width: 900px) {
    margin: 0 0 0 -4rem;
  }

  @media (max-width: 750px) {
    margin: 0 0 0 0;
  }
`;

const HeadingLineWrapper = styled.div`
  grid-area: line;
  margin: 0 0 0 -8rem;

  @media (max-width: 900px) {
    margin: 0 0 0 -4rem;
  }

  @media (max-width: 750px) {
    margin: 0 0 0 0;
  }
`;

const TextWrapper = styled.div`
  grid-area: text;
  display: grid;
  grid-auto-rows: min-content;
  background-color: ${colors.primary.v100};
  padding: 2em;
  border-radius: 3em;
  border: solid .6em ${colors.primary.v200};
  border-radius: 3em;

  @media (max-width: 400px) {
    padding: 1em;
  }
`;

const CTAWrapper = styled.div`
  display: grid;
  grid-area: cta;
  grid-template-areas: "cta-text" "button";
  grid-template-rows: 10em 2.5em;
  grid-template-columns: 1fr;
  align-self: center;
  justify-self: center;
  margin: 6em 0 0 0;
`;

const CTAText = styled.h2`
  grid-area: cta-text;
  width: 11em;
  color: ${colors.primary.v100};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
`;

const Button = styled(Link)`
  display: grid;
  grid-area: button;
  grid-template-areas: "text icon";
  grid-template-columns: 7em 3em;
  grid-template-rows: 1fr;
  width: 10em;
  background-color: ${colors.accentTwo.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 0 0 0 0;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: 0 .2em 0 0;

  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.secondary.v500};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonText = styled.h6`
  grid-area: text;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8em;
  color: ${colors.text.o90};
  text-align: left;
  line-height: 1.5;
  margin: .2em 0 0 0;
`;

function ClinicalTrial () {
  return (
    <ClinicalTrialWrapper>
      <Helmet>
        <title>Clinical Trial</title>
        <meta name="description" content="Herald Therapeutics Clinical Trial Information" />
      </Helmet>
      <ContentWrapper>
        <LogoWrapper>
          <Suspense>
            <ThyrOxyLogo />
          </Suspense>
        </LogoWrapper>
        <HeadingLineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v300} strokeWidth="1.4em" />
          </svg>
        </HeadingLineWrapper>
        <Heading>Clinical Trial</Heading>
        <TextWrapper>
          <Description gridArea="auto">Herald Therapeutics, in concert with the University of Minnesota (UMN), is in clinical stage development to treat Acute Respiratory Distress Syndrome (ARDS), using ThyrOxy as the drug treatment therapy.  In preclinical studies, ThyrOxy has been shown to reduce pulmonary inflammation, as well as reduce pulmonary edema (fluid in the lungs).</Description>
          <Description gridArea="auto">The Phase 1 clinical trial is completed.  Phase 1 evaluated ThyrOxy drug safety and tolerability in patients with ARDS.  The ThyrOxy Phase 1 results had zero drug-related Adverse Events (AEs) and zero Severe Adverse Events (SAEs).</Description>
          <Description gridArea="auto">Accordingly, after successful completion of Phase 1, the FDA approved the start of a Phase 2, to evaluate likely ThyrOxy therapy efficacy (NCT 04115514, IND 126204).</Description>
          <Description gridArea="auto">The project is currently in an on-going Phase 2 randomized clinical trial at multiple UMN hospitals for ARDS where investigators are observing early potential efficacy of ThyrOxy treatment.   To date, no drug-related AEs or SAEs have been observed in Phase 2.</Description>
          <Description gridArea="auto">Please contact Herald Therapeutics regarding confidential Phase 2 information.  ThyrOxy, and its use to treat ARDS, are presently in a randomized, controlled clinical trial and has not been approved for commercial use by FDA or any other regulatory body.</Description>
        </TextWrapper>
        <CTAWrapper>
          <CTAText>See our patents and research.</CTAText>
          <Button to="/research-ip">
            <ButtonText>Research & IP</ButtonText>
            <Arrow />
          </Button>
        </CTAWrapper>
      </ContentWrapper>
    </ClinicalTrialWrapper>
  );
}

export default ClinicalTrial;
