export const thyroxyDirect = [
  {
    indication: 'ARDS (mechanically ventilated)',
    stage: 'Phase 2',
    percent: '40',
    baseColor: 'accent',
    small: {
      percent: 40,
      bars: {
        research: { percent: 100, name: 'Research' },
        preclinical: { percent: 100, name: 'Pre-Clinical' },
        phase1: { percent: 100, name: 'Phase 1' },
        phase2: { percent: 40, name: 'Phase 2' },
      },
    },
    big: {
      research: '108',
      preclinical: '108',
      phase1: '108',
      phase2: '40',
      gridArea: 'thyroxy-direct-ards',
    },
  },
  {
    indication: 'Confidential',
    stage: 'Pre-Clinical',
    percent: '80',
    baseColor: 'accent',
    small: {
      percent: 80,
      bars: {
        research: { percent: 100, name: 'Research' },
        preclinical: { percent: 80, name: 'Pre-Clinical' },
        phase1: { percent: 0, name: 'Phase 1' },
        phase2: { percent: 0, name: 'Phase 2' },
      },
    },
    big: {
      research: '108',
      preclinical: '80',
      gridArea: 'thyroxy-direct-confidential',
    },
  },
  {
    indication: 'Infant RDS (mechanically ventilated)',
    stage: 'Pre-Clinical',
    percent: '40',
    baseColor: 'accent',
    small: {
      percent: 40,
      bars: {
        research: { percent: 100, name: 'Research' },
        preclinical: { percent: 48, name: 'Pre-Clinical' },
        phase1: { percent: 0, name: 'Phase 1' },
        phase2: { percent: 0, name: 'Phase 2' },
      },
    },
    big: {
      research: '108',
      preclinical: '48',
      gridArea: 'thyroxy-direct-infant-ards',
    },
  },
];

export const thyroxyInhaled = [
  {
    indication: 'ARDS (all stages)',
    stage: 'Pre-Clinical',
    percent: '40',
    baseColor: 'accentTwo',
    small: {
      percent: 40,
      bars: {
        research: { percent: 100, name: 'Research' },
        preclinical: { percent: 48, name: 'Pre-Clinical' },
        phase1: { percent: 0, name: 'Phase 1' },
        phase2: { percent: 0, name: 'Phase 2' },
      },
    },
    big: {
      research: '108',
      preclinical: '48',
      gridArea: 'thyroxy-inhaled-infant',
    },
  },
  {
    indication: 'Congestive Heart Failure',
    stage: 'Pre-Clinical',
    percent: '15',
    baseColor: 'accentTwo',
    small: {
      percent: 23,
      bars: {
        research: { percent: 100, name: 'Research' },
        preclinical: { percent: 23, name: 'Pre-Clinical' },
        phase1: { percent: 0, name: 'Phase 1' },
        phase2: { percent: 0, name: 'Phase 2' },
      },
    },
    big: {
      research: '108',
      preclinical: '23',
      gridArea: 'thyroxy-inhaled-chf',
    },
  },
  {
    indication: 'Infant RDS (all stages)',
    stage: 'Pre-Clinical',
    percent: '15',
    baseColor: 'accentTwo',
    small: {
      percent: 23,
      bars: {
        research: { percent: 100, name: 'Research' },
        preclinical: { percent: 23, name: 'Pre-Clinical' },
        phase1: { percent: 0, name: 'Phase 1' },
        phase2: { percent: 0, name: 'Phase 2' },
      },
    },
    big: {
      research: '108',
      preclinical: '23',
      gridArea: 'thyroxy-inhaled-confidential',
    },
  },
  {
    indication: 'Pneumonitis',
    stage: 'Pre-Clinical',
    percent: '15',
    baseColor: 'accentTwo',
    small: {
      percent: 23,
      bars: {
        research: { percent: 100, name: 'Research' },
        preclinical: { percent: 23, name: 'Pre-Clinical' },
        phase1: { percent: 0, name: 'Phase 1' },
        phase2: { percent: 0, name: 'Phase 2' },
      },
    },
    big: {
      research: '108',
      preclinical: '23',
      gridArea: 'thyroxy-inhaled-pneumonitis',
    },
  },
];

export const htx2 = [
  {
    indication: 'Multiple Pulmonary and Cardiac (confidential)',
    stage: 'Research',
    percent: '108',
    baseColor: 'primary',
    small: {
      percent: 100,
      bars: {
        research: { percent: 100, name: 'Research' },
        preclinical: { percent: 0, name: 'Pre-Clinical' },
        phase1: { percent: 0, name: 'Phase 1' },
        phase2: { percent: 0, name: 'Phase 2' },
      },
    },
    big: {
      research: '108',
      gridArea: 'htx-2-chf',
    },
  },
];
