import { lazy, Suspense } from 'react';
import styled from 'styled-components';

import colors from '../../styles/colors';

import { H3, H6, Description } from '../ui/text';

const NaKPump = lazy(() => import('./NaKPump'));
const NucleatedCells = lazy(() => import('./NucleatedCells'));

const TopSideBySideCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: .5fr 1fr;
  grid-template-areas: "top top" "left right";
  background-color: ${colors.primary.v800};
  border: .5em solid ${colors.primary.v700};
  border-radius: 3em;
  width: 70em;
  justify-self: center;
  margin: 4em 0 10em 0;
  column-gap: 4em;
  padding: 6em 4em 6em 4em;

  @media (max-width: 1300px) {
    width: auto;
    margin: 4em 1em 10em 1em;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    grid-template-areas: "top" "left" "right";
    row-gap: 4em;
  }

  @media (max-width: 700px) {
    padding: 0 2em 2em 4em;
  }

  @media (max-width: 500px) {
    padding: 1em 1em 1em 1em;
  }

  @media (max-width: 425px) {
    margin: 4em .5em 10em .5em;
  }
`;

const VerticalImageWrapper = styled.div`
  grid-area: ${(props) => props.gridArea || 'right'};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 25em 1fr;
  row-gap: 3em;
  grid-template-areas: "image" "description";
  align-self: top;
  max-width: 70ch;
`;

const HeadingListDescriptionWrapper = styled.div`
  grid-area: ${(props) => props.gridArea || 'right'};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 6em 1em min-content min-content;
  grid-template-areas: "heading" "line"  "list" "description";
  align-self: top;
  justify-self: center;
  margin: 0 0 3em 0;

  @media (max-width: 1300px) {
    max-width: 70ch;
    margin: 2em 0 0 0;
  }

  @media (max-width: 500px) {
    margin: 0 0 0 0;
  }
`;

const ImageDescriptionWrapper = styled.div`
  grid-area: ${(props) => props.gridArea || 'image'};
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr;
  grid-template-areas: "image-title" "image";
  row-gap: 2em;
`;

const ImageWrapper = styled.div`
  display: grid;
  justify-self: center;
  grid-area: image;
  width: 25em;

  @media (max-width: 1300px) {
    width: 50vw;
  }

  @media (max-width: 425px) {
    width: 70vw;
  }
`;

const LineWrapper = styled.div`
  grid-area: ${(props) => props.gridArea || 'auto'};
  display: grid;
  margin: ${(props) => props.margin || '0 0 0 0'};
  justify-self: ${(props) => props.justifySelf || 'left'};
`;

const LI = styled.li`
  color: ${colors.greys.one};
  margin: 0 0 0 -2.5rem;

  @media (max-width: 500px) {
    margin: 0 0 0 -1.5rem;
  }
`;

function TopSideBySideCard () {
  return (
    <TopSideBySideCardWrapper>
      <VerticalImageWrapper gridArea="left">
        <ImageDescriptionWrapper>
          <H6
            color={colors.primary.v100}
            gridArea="image-title"
            justifySelf="center"
            textAlign="center"
          >
            Neutrophilic Inflammation of the Lung
          </H6>
          <ImageWrapper>
            <Suspense>
              <NucleatedCells />
            </Suspense>
          </ImageWrapper>
        </ImageDescriptionWrapper>
        <Description color={colors.greys.one} gridArea="description" alignSelf="top">Hyperoxia-induced acute lung injury is a model of ARDS characterized by lung inflammation, increased permeability, and death of endothelial and epithelial cells. In a rat model, hyperoxia exposure decreased the serum total T3 concentration by 65%, versus room air exposure. The lung tissue MPO (myeloperoxidase) activity, BALF (bronchoalveolar lavage fluid) total protein and nucleated cells each increased over threefold with hyperoxia compared to normoxia.  However, T3 treatment reduced the increases in each parameter by ~40%.  T3 treatment also significantly decreased the hyperoxic increase in the number of macrophages in the BALF. Thus, T3 reduces lung inflammation induced by hyperoxia.</Description>
      </VerticalImageWrapper>
      <VerticalImageWrapper gridArea="right">
        <ImageDescriptionWrapper>
          <H6
            color={colors.primary.v100}
            gridArea="image-title"
            justifySelf="center"
            textAlign="center"
          >
            Na-K-ATPase Pump
          </H6>
          <ImageWrapper>
            <Suspense>
              <NaKPump />
            </Suspense>
          </ImageWrapper>
        </ImageDescriptionWrapper>
        <Description color={colors.greys.one} gridArea="description" alignSelf="top">T3 specifically stimulates Na-K-ATPase pump activity in alveolar (airspace) of the lung to clear water. The upregulation involves translocation of Na-K-ATPase to plasma membrane, not increased gene transcription. This is a novel transcriptional mechanism for regulation of Na/K-ATPase by thyroid hormone (Lei, Nowbar, Mariash & Ingbar.  Am J Physiol 2003 285(3):L762-772. Lei Mariash & Ingbar J Biol Chem 2004 279(46):47589-600.). T3 rapidly stimulates clearance of pulmonary edema fluid - even in the injured lung (Bhargava, ... Mariash, Wangensteen & Ingbar.  Am J Respir Crit Care Med 2008 178(5):506-512.).</Description>
      </VerticalImageWrapper>
      <HeadingListDescriptionWrapper gridArea="top">
        <H3
          gridArea="heading"
          justifySelf="left"
          color={colors.primary.v100}
        >
          Reduces lung inflammation
          <br />
          Reduces lung water (edema)
        </H3>
        <LineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v500} strokeWidth=".25em" />
          </svg>
        </LineWrapper>
        <ol style={{ gridArea: 'list' }}>
          <LI>
            <Description color={colors.greys.one}>
              ThyrOxy decreases lung inflammation and injury from oxidative stress.
            </Description>
          </LI>
          <LI>
            <Description color={colors.greys.one}>
              ThyrOxy clears alveolar fluid (pulmonary edema) through activation of the Na/K-ATPase pump.
            </Description>
          </LI>
        </ol>
        <Description color={colors.greys.one}>
          These mechanisms of action result in improved oxygen diffusion into the blood for circulation.
        </Description>
      </HeadingListDescriptionWrapper>
    </TopSideBySideCardWrapper>
  );
}

export default TopSideBySideCard;
