import styled from 'styled-components';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

function gridArea (props) { return props.gridArea || 'auto'; }
function gridAreaDescription (props) { return props.gridArea || 'description'; }
function color (props) { return props.color || colors.text.o90; }
function margin (props) { return props.margin || '0 0 0 0'; }
function alignSelf (props) { return props.alignSelf || 'center'; }
function justifySelf (props) { return props.justifySelf || 'center'; }
function textAlign (props) { return props.textAlign || 'left'; }

export const JumboText = styled.h1`
  grid-area: ${gridArea};
  color: ${color};
  font-size: 3em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  margin: ${margin};
  align-self: ${alignSelf};
  justify-self: ${justifySelf};

  @media (max-width: 475px) {
    font-size: 2.5em;
  }
`;

export const H2 = styled.h2`
  grid-area: ${gridArea};
  color: ${color};
  font-size: 1.8em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  margin: ${margin};
  align-self: ${alignSelf};
  justify-self: ${justifySelf};
  line-height: 1.8;

  @media (max-width: 450px) {
    font-size: 1.6em;
  }
`;

export const H3 = styled.h3`
  grid-area: ${gridArea};
  color: ${color};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: ${alignSelf};
  justify-self: ${justifySelf};
  margin: ${margin};
  font-size: 1.6em;
  line-height: 1.5;
  text-align: ${(props) => props.textAlign || 'left'};

  @media (max-width: 450px) {
    font-size: 1.4em;
  }
`;

export const H6 = styled.h6`
  grid-area: ${gridArea};
  color: ${color};
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.weight};
  margin: ${margin};
  align-self: ${alignSelf};
  justify-self: ${justifySelf};
  font-size: .9em;
  line-height: 1.2;
  text-align: ${textAlign};
`;

export const Description = styled.p`
  grid-area: ${gridAreaDescription};
  line-height: 2;
  color: ${color};
  justify-self: ${justifySelf};
  align-self: ${alignSelf};

  @media (max-width: 450px) {
    font-size: .9em;
  }
`;

export const Link = styled.a`
  grid-area: ${gridArea};
  color: ${color};
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.weight};
  margin: ${margin};
  align-self: ${alignSelf};
  justify-self: ${justifySelf};
  font-size: 1em;
  line-height: 1.2;
  text-align: ${textAlign};
`;
