import PolicyContainer from './PolicyContainer';
import useTermly from './useTermly';

function Terms () {
  useTermly();
  return (
    <PolicyContainer>
      <div name="termly-embed" data-id="84379b36-cd83-4743-9166-ecefbd4c8f6b" data-type="iframe" />
    </PolicyContainer>
  );
}

export default Terms;
