import { useEffect } from 'react';

function useTermly () {
  useEffect(() => {
    const js = document.createElement('script');
    js.id = 'termly-jssdk';
    js.src = 'https://app.termly.io/embed-policy.min.js';
    document.body.appendChild(js);

    return () => {
      const termly = document.getElementById('termly-jssdk');
      document.body.removeChild(termly);
    };
  }, []);
}

export default useTermly;
