import { memo } from 'react';
import styled from 'styled-components';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

import SmallGraphBar from './SmallGraphBar';

const IndicationCard = styled.div`
  display: grid;
  color: ${(props) => props.color};
  grid-template-columns: 1fr;
  grid-template-areas: "indication-label" "indication" "stage-label" "stage"  "bar";
  grid-template-rows: 1em 3em 2em 3em 1fr;
  border: .3em solid ${colors.primary.v200};
  border-radius: 1em;
  padding: 1em;
`;

const Label = styled.h6`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8em;
  text-transform: uppercase;
  justify-self: left;
  align-self: end;
  line-height: 0;
  margin: 1em 0 0 0;
  color: ${colors.greys.six};
`;

const Text = styled.p`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};   
  color: ${colors.text.o90};
  font-family: ${fonts.regular.fontFamily};
  font-weight: ${fonts.regular.regular.weight};
  justify-self: left;
  align-self: center;
  text-align: left;
`;

const SmallBarsWrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
`;

function SmallGraphIndicationCard ({
  indication,
  stage,
  baseColor,
  bars,
}) {
  return (
    <IndicationCard>
      <Label gridArea="indication-label">
        Indication
      </Label>
      <Text gridArea="indication">
        {indication}
      </Text>
      <Label gridArea="stage-label">
        Stage
      </Label>
      <Text gridArea="stage">
        {stage}
      </Text>
      <SmallBarsWrapper>
        {Object.entries(bars).map(([title, { percent, name }]) => (
          <SmallGraphBar key={title} title={name} percent={percent} baseColor={baseColor} />
        ))}
      </SmallBarsWrapper>
    </IndicationCard>
  );
}

export default memo(SmallGraphIndicationCard);
