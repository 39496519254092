/*

<link rel="stylesheet" href="https://use.typekit.net/unb6ngp.css">

FIRA-MONO BOLD
font-family: fira-mono, monospace;
font-weight: 700;
font-style: normal;

DIN CONDENSED
font-family: din-condensed, sans-serif;
font-weight: 400;
font-style: normal;

*/

const fonts = {
  mono: {
    fontFamily: 'fira-mono, monospace',
    bold: {
      weight: 700,
      style: 'normal',
    },
  },
  condensed: {
    fontFamily: 'din-condensed, sans-serif',
    regular: {
      weight: 500,
      style: 'normal',
    },
  },
  regular: {
    fontFamily: 'Roboto Slab, sans-serif',
    regular: {
      weight: 400,
      style: 'normal',
    },
  },
};

export default fonts;
