import { useState } from 'react';
import styled from 'styled-components';
import { animated, useTransition, easings } from 'react-spring';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const TeamCardWrapper = styled.div`
  display: grid;
  grid-template-areas: "main";
  border: .5em solid ${colors.primary.v200};
  border-radius: 2em;
  overflow: hidden;
`;

const TeamImg = styled.img`
  grid-area: main;
  width: 100%;
`;

const TeamHoverWrapper = styled(animated.div)`
  grid-area: main;
  display: grid;
  grid-template-areas: "name" "title" "button";
  grid-template-rows: 4em 2em 1fr;
  grid-template-columns: 1fr;
  background-color: rgba(48, 86, 113, .85);
  padding: 1em;
`;

const TeamHoverName = styled.h2`
  grid-area: name;
  color: ${colors.greys.one};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
`;

const TeamHoverTitle = styled.h6`
  grid-area: title;
  color: ${colors.primary.v100};
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1em;
  line-height: 1.5;
`;

const Button = styled.button`
  width: 12em;
  height: 3em;
  background-color: ${colors.secondary.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 0 0 1em 0;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: 0 .2em 0 0;
  align-self: end;
  justify-self: center;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.weight};

  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.accent.v500};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

function TeamCard ({ imgSrc, name, title, onClick }) {
  const [isHovered, setIsHovered] = useState(false);

  function handleMouseEnter () {
    setIsHovered(true);
  }

  function handleMouseLeave () {
    setIsHovered(false);
  }

  const transition = useTransition(isHovered, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: true,
    config: {
      friction: 40,
      easing: easings.easeInOutSine,
    },
  });

  return (
    <TeamCardWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <TeamImg src={imgSrc} alt={name} />
      {transition((styles, item) => item && (
        <TeamHoverWrapper style={styles}>
          <TeamHoverName>{name}</TeamHoverName>
          <TeamHoverTitle>{title}</TeamHoverTitle>
          <Button>Read Bio</Button>
        </TeamHoverWrapper>
      ))}
    </TeamCardWrapper>
  );
}

export default TeamCard;
