import { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ReactComponent as Arrow } from '../svg/Arrow.svg';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const ARDS = lazy(() => import('./ARDS'));

const ThyrOxy = lazy(() => import('./ThyrOxy'));

const ScienceWrapper = styled.section`
  display: grid;
  margin: 0 0 0 0;
  grid-template-areas: "main";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const BackgroundWrapper = styled.div`
  grid-area: main;
  background-color: ${colors.primary.v700};
  height: 20em;
  border-bottom: .5em solid ${colors.primary.v300};
  z-index: 10;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-area: main;
  grid-template-areas: "line" "heading" "ards" "thyroxy" "cta";
  grid-template-columns: 1fr;
  grid-template-rows: 2em 8em auto auto 10em;
  margin: 10em 0 10em 0;
`;

const Heading = styled.h1`
  grid-area: heading;
  color: ${colors.primary.v100};
  font-size: 2em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  margin: 0 0 0 16rem;
  z-index: 11;

  @media (max-width: 1000px) {
    margin: 0 0 0 8rem;
  }

  @media (max-width: 700px) {
    margin: 0 0 0 1rem;
  }
`;

const LineWrapper = styled.div`
  grid-area: line;
  margin: 0 0 0 16rem;
  z-index: 11;

  @media (max-width: 1000px) {
    margin: 0 0 0 8rem;
  }

  @media (max-width: 700px) {
    margin: 0 0 0 1rem;
  }
`;

const CTAWrapper = styled.div`
  display: grid;
  grid-area: cta;
  grid-template-areas: "cta-text" "button";
  grid-template-rows: 10em 2.5em;
  grid-template-columns: 1fr;
  align-self: center;
  justify-self: center;
  margin: 6em 0 0 0;
`;

const CTAText = styled.h2`
  grid-area: cta-text;
  width: 11em;
  color: ${colors.text.o90};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
`;

const Button = styled(Link)`
  display: grid;
  grid-area: button;
  grid-template-areas: "text icon";
  grid-template-columns: 7em 3em;
  grid-template-rows: 1fr;
  width: 10em;
  background-color: ${colors.accentTwo.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 0 0 0 0;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: 0 .2em 0 0;

  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.secondary.v500};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonText = styled.h6`
  grid-area: text;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8em;
  color: ${colors.text.o90};
  text-align: left;
  line-height: 1.5;
  margin: .2em 0 0 0;
`;

function Science () {
  return (
    <ScienceWrapper>
      <BackgroundWrapper />
      <Helmet>
        <title>Science</title>
        <meta name="description" content="ThyrOxy is hormone replacement for the lung. First instillation of thyroid hormones into the lung in the world." />

        <meta property="og:title" content="Herald TX - Science" />
        <meta property="og:description" content="ThyrOxy is hormone replacement for the lung. First instillation of thyroid hormones into the lung in the world." />
        <meta property="og:url" content="https://www.heraldtx.com/science" />
        <meta property="og:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
        <meta property="og:image:alt" content="Herald Therapeutics Logo and Tagline: Advancing research and science for pulmonary care." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Herald Therapeutics" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.heraldtx.com/science" />
        <meta property="twitter:title" content="Herald TX - Science" />
        <meta property="twitter:description" content="ThyrOxy is hormone replacement for the lung. First instillation of thyroid hormones into the lung in the world." />
        <meta property="twitter:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
      </Helmet>
      <ContentWrapper>
        <LineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v400} strokeWidth="1.4em" />
          </svg>
        </LineWrapper>
        <Heading>Science</Heading>
        <Suspense>
          <ARDS />
        </Suspense>
        <Suspense>
          <ThyrOxy />
        </Suspense>
        <CTAWrapper>
          <CTAText>Find out where we are going.</CTAText>
          <Button to="/pipeline">
            <ButtonText>Pipeline</ButtonText>
            <Arrow />
          </Button>
        </CTAWrapper>
      </ContentWrapper>
    </ScienceWrapper>
  );
}

export default Science;
