import styled from 'styled-components';

import colors from '../../styles/colors';

const PolicyWrapper = styled.div`
  display: grid;
  margin: 10em 0 5em 0;
  justify-self: center;
  min-width: 50em;
  padding: 0 2em 0 2em;
  border: .3em solid ${colors.greys.five};
  border-radius: 2em;
  background-color: ${colors.greys.one};

  @media (max-width: 1000px) {
    min-width: 25em;
  }

  @media (max-width: 620px) {
    min-width: 0;
  }
`;

function PolicyContainer ({ children }) {
  return (
    <PolicyWrapper>{children}</PolicyWrapper>
  );
}

export default PolicyContainer;
