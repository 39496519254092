import { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { WindowContext } from '../../context/WindowContext';

import { ReactComponent as BackgroundOne } from './research-line-1.svg';
import { ReactComponent as BackgroundTwo } from './research-line-2.svg';
import { ReactComponent as ExternalLinkArrow } from '../svg/external-link-icon.svg';
import { ReactComponent as Arrow } from '../svg/Arrow.svg';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const ResearchWrapper = styled.section`
  display: grid;
  margin: 0 0 0 0;
  grid-template-areas: "main";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const BackgroundWrapper = styled.div`
  grid-area: main;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-area: main;
  grid-template-areas: "line" "heading" "research" "cta";
  grid-template-columns: 1fr;
  grid-template-rows: 2em 10em auto 10em;
  margin: 10em 10em 10em 10em;
  max-width: 1200px;
  justify-self: center;

  @media (max-width: 1400px) {
    margin: 10em 5em 10em 5em;
  }

  @media (max-width: 800px) {
    margin: 10em 1em 10em 1em;
  }
`;

const Heading = styled.h1`
  grid-area: heading;
  color: ${colors.primary.v700};
  font-size: 2em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  margin: 0 0 0 8rem;

  @media (max-width: 700px) {
    margin: 0 0 0 4rem;
  }

  @media (max-width: 500px) {
    margin: 0 0 0 2rem;
  }
`;

const LineWrapper = styled.div`
  grid-area: line;
  margin: 0 0 0 8rem;

  @media (max-width: 700px) {
    margin: 0 0 0 4rem;
  }

  @media (max-width: 500px) {
    margin: 0 0 0 2rem;
  }
`;

const ResearchLinksWrapper = styled.div`
  grid-area: research;
  display: grid;
  grid-template-columns: 1fr;
  grit-auto-rows: min-content;
  background-color: ${colors.primary.v100};
  border: solid .6em ${colors.primary.v200};
  border-radius: 5em;
  padding: 4em 3em 4em 3em;
  margin: 0 0 1em 0;
  row-gap: 4em;

  @media (max-width: 425px) {
    padding: 4em 1em 4em 1em;
  }
`;

const ResearchCategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: .76fr 1.33fr;
  grid-template-rows: 1fr;
  grid-template-areas: "category links";
  column-gap: 6em;

  @media (max-width: 1200px) {
    grid-template-rows: auto 1.33fr;
    grid-template-columns: 1fr;
    grid-template-areas: "category" "links";
  }
`;

const Divider = styled.div`
  height: .25em;
  background-color: ${colors.primary.v300};
`;

const CategoryText = styled.h2`
  grid-area: category;
  width: 11em;
  color: ${colors.primary.v800};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: top;
  justify-self: right;
  margin: 0 0 0 0;
  font-size: 1.8em;
  height: ${(props) => props.height || '2em'};
  border-bottom: .1em solid ${colors.primary.v500};
  text-align: right;

  @media (max-width: 1300px) {
    text-align: left;
    justify-self: left;
    margin: 0 0 1em 0;
  }

  @media (max-width: 500px) {
    width: auto;
  }
`;

const LinksWrapper = styled.div`
  grid-area: links;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  row-gap: 2em;
`;

const ExternalButton = styled.a`
  display: grid;
  grid-template-areas: "text icon";
  grid-template-columns: 1fr 2em;
  grid-template-rows: 1fr;
  background-color: ${colors.secondary.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 0 0 0 0;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: 1em 1.5em 1em 1.5em;
  max-width: 70ch;
  color: ${colors.text.o90};


  @media (max-width: 500px) {
    grid-template-areas: "text" "icon";
    grid-template-rows: auto 1.5em;
    grid-template-columns: 1fr;
    place-items: right;
  }


  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.accent.v300};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonText = styled.h6`
  font-family: ${fonts.regular.fontFamily};
  font-weight: ${fonts.regular.regular.weight};
  font-size: .8em;
  color: ${colors.text.o90};
  text-align: left;
  line-height: 1.5;
  margin: .2em 0 0 0;
`;

const ButtonHeading = styled.h6`
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .9em;
  color: ${colors.text.o90};
  text-align: left;
  line-height: 1.5;
  margin: .2em 0 0 0;
`;

const ButtonTextCategory = styled.p`
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8rem;
  color: ${colors.secondary.v800};
  text-align: left;
  line-height: 1.5;
  margin: .2em 0 0 0;
`;

const CTAWrapper = styled.div`
  display: grid;
  grid-area: cta;
  grid-template-areas: "cta-text" "button";
  grid-template-rows: 10em 2.5em;
  grid-template-columns: 1fr;
  align-self: center;
  justify-self: center;
  margin: 6em 0 0 0;
`;

const CTAText = styled.h2`
  grid-area: cta-text;
  width: 11em;
  color: ${colors.text.o90};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
`;

const Button = styled(Link)`
  display: grid;
  grid-area: button;
  grid-template-areas: "text icon";
  grid-template-columns: 7em 3em;
  grid-template-rows: 1fr;
  width: 10em;
  background-color: ${colors.accentTwo.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 0 0 0 0;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: 0 .2em 0 0;

  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.secondary.v500};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonTextWrapper = styled.div`
  grid-area: text;
  display: grid;
  margin: 0;
  justify-self: start;
`;

function Research () {
  const windowContext = useContext(WindowContext);
  const { screenWidth } = windowContext;

  return (
    <ResearchWrapper>
      <Helmet>
        <title>Research & IP</title>
        <meta name="description" content="Herald Therapeutics Team have published multiple studies about thyroid hormone in the lung. They also have an issued patent." />

        <meta property="og:title" content="Herald TX - Research & IP" />
        <meta property="og:description" content="Herald Therapeutics Team have published multiple studies about thyroid hormone in the lung. They also have an issued patent." />
        <meta property="og:url" content="https://www.heraldtx.com/research" />
        <meta property="og:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
        <meta property="og:image:alt" content="Herald Therapeutics Logo and Tagline: Advancing research and science for pulmonary care." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Herald Therapeutics" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.heraldtx.com/research" />
        <meta property="twitter:title" content="Herald TX - Research" />
        <meta property="twitter:description" content="Herald Therapeutics Team have published multiple studies about thryroid hormone in the lung. They also have an issued patent." />
        <meta property="twitter:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
      </Helmet>
      <BackgroundWrapper>
        <BackgroundOne />
        <BackgroundTwo />
      </BackgroundWrapper>
      <ContentWrapper>
        <LineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v300} strokeWidth="1.4em" />
          </svg>
        </LineWrapper>
        <Heading>Research & IP</Heading>
        <ResearchLinksWrapper>
          <ResearchCategoryWrapper>
            <CategoryText height={screenWidth < 428 ? '4em' : '3em'}>Patents</CategoryText>
            <LinksWrapper>
              <ExternalButton
                href="https://htx-research.s3.amazonaws.com/US-11458094-B2_I.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonTextWrapper>
                  <ButtonTextCategory>Acute Respiratory Distress Syndrome</ButtonTextCategory>
                  <ButtonHeading>Compositions and Methods for Treating Pulmonary Edema or Lung Inflammation</ButtonHeading>
                  <ButtonText>United States 11,458,094 B2 - Issued Oct 4, 2022</ButtonText>
                </ButtonTextWrapper>
                <ExternalLinkArrow style={screenWidth < 500 ? { justifySelf: 'right' } : null} />
              </ExternalButton>
              <ExternalButton
                href="https://htx-research.s3.amazonaws.com/HTX-US11883528.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonTextWrapper>
                  <ButtonTextCategory>Congestive Heart Failure</ButtonTextCategory>
                  <ButtonHeading>Compositions and Methods for Treating Pulmonary Edema or Lung Inflammation</ButtonHeading>
                  <ButtonText>United States 11,883,528 - Issued Jan 30, 2024</ButtonText>
                </ButtonTextWrapper>
                <ExternalLinkArrow style={screenWidth < 500 ? { justifySelf: 'right' } : null} />
              </ExternalButton>
              <ExternalButton
                href="https://htx-research.s3.amazonaws.com/HTX-Letters-Patent-402425.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonTextWrapper>
                  <ButtonTextCategory>Acute Respiratory Distress Syndrome</ButtonTextCategory>
                  <ButtonHeading>
                    Composiciones y Métodos para Tratar Edema Pulmonar o Inflamación Pulmonar
                  </ButtonHeading>
                  <ButtonText>Mexico 402,425 - Issued May 9, 2023</ButtonText>
                </ButtonTextWrapper>
                <ExternalLinkArrow style={screenWidth < 500 ? { justifySelf: 'right' } : null} />
              </ExternalButton>
              <ExternalButton
                href="https://htx-research.s3.amazonaws.com/HTX-India-ARDS-Patent-513771.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonTextWrapper>
                  <ButtonTextCategory>Acute Respiratory Distress Syndrome</ButtonTextCategory>
                  <ButtonHeading>
                    Compositions and Methods for Treating Pulmonary Edema or Lung Inflammation
                  </ButtonHeading>
                  <ButtonText>India 513,771 - Issued Feb 22, 2024</ButtonText>
                </ButtonTextWrapper>
                <ExternalLinkArrow style={screenWidth < 500 ? { justifySelf: 'right' } : null} />
              </ExternalButton>
            </LinksWrapper>
          </ResearchCategoryWrapper>
          <Divider />
          <ResearchCategoryWrapper>
            <CategoryText height={screenWidth < 428 ? '4em' : '3em'}>Mechanism of Action and Preclinical Proof of Concept</CategoryText>
            <LinksWrapper>
              <ExternalButton
                href="https://htx-research.s3.amazonaws.com/T3+AEC+NaK-ATPase+J.+Biol.+Chem.-2004-Lei-47589-600.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonText>3,3&#39;,5-Triiodo-L-thyronine Up-regulation of Na,K-ATPase Activity and Cell Surface Expression in Alveolar Epithelial Cells Is Src Kinase- and Phosphoinositide 3-Kinase-dependent</ButtonText>
                <ExternalLinkArrow style={screenWidth < 500 ? { justifySelf: 'right' } : null} />
              </ExternalButton>
              <ExternalButton
                href="https://htx-research.s3.amazonaws.com/Lei+DHI+AJP+Lung+2011+-+T3.00151.2011.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonText>Src kinase integrates PI3K/Akt and MAPK/ERK1/2 pathways in T3-induced Na-K-ATPase activity in adult rat alveolar cells</ButtonText>
                <ExternalLinkArrow style={screenWidth < 500 ? { justifySelf: 'right' } : null} />
              </ExternalButton>
              <ExternalButton
                href="https://htx-research.s3.amazonaws.com/Bhargava+AJRCCM+2008+T3+stimulates+AFC+1785506.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonText>Triiodo-L-thyronine Rapidly Stimulates Alveolar Fluid Clearance in Normal and Hyperoxia-injured Lungs</ButtonText>
                <ExternalLinkArrow style={screenWidth < 500 ? { justifySelf: 'right' } : null} />
              </ExternalButton>
            </LinksWrapper>
          </ResearchCategoryWrapper>
          <Divider />
          <ResearchCategoryWrapper>
            <CategoryText>Preclinical Safety</CategoryText>
            <LinksWrapper>
              <ExternalButton
                href="https://htx-research.s3.amazonaws.com/Thyroxy-Preclinical.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonText>A Preclinical Safety Study of Thyroid Hormone Instilled into the Lungs of Healthy Rats — an Investigational Therapy for ARDS</ButtonText>
                <ExternalLinkArrow style={screenWidth < 500 ? { justifySelf: 'right' } : null} />
              </ExternalButton>
            </LinksWrapper>
          </ResearchCategoryWrapper>
          <Divider />
          <ResearchCategoryWrapper>
            <CategoryText>Overview Posters</CategoryText>
            <LinksWrapper>
              <ExternalButton
                href="https://htx-research.s3.amazonaws.com/ARDS+poster+Boston+Drug+Developmnet+2022JULY12.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonText>Development of Thyroid Hormone as a Treatment Therapy for Acute Respiratory Distress Syndrome</ButtonText>
                <ExternalLinkArrow style={screenWidth < 500 ? { justifySelf: 'right' } : null} />
              </ExternalButton>
            </LinksWrapper>
          </ResearchCategoryWrapper>
        </ResearchLinksWrapper>
        <CTAWrapper>
          <CTAText>Learn how we got here.</CTAText>
          <Button to="/story">
            <ButtonText>Our Story</ButtonText>
            <Arrow />
          </Button>
        </CTAWrapper>
      </ContentWrapper>
    </ResearchWrapper>
  );
}

export default Research;
