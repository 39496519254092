import { useState, memo } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';
import LoadingSpinnerSmall from './LoadingSpinnerSmall';

const API_URL = process.env.REACT_APP_API_URL;

const ContactWrapper = styled.section`
  display: grid;
  margin: 0 0 0 0;
  grid-template-areas: "main";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-area: main;
  grid-template-areas: "line" "heading" "form" "address";
  grid-template-columns: 1fr;
  grid-template-rows: 2em 8em auto auto;
  margin: 10em 0 10em 0;
  max-width: 1000px;
  justify-self: center;
`;

const Heading = styled.h1`
  grid-area: heading;
  color: ${colors.primary.v700};
  font-size: 2em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  margin: 0 0 0 0;
`;

const HeadingLineWrapper = styled.div`
  grid-area: line;
  margin: 0 0 0 0;
`;

const AddressWrapper = styled.div`
  grid-area: address;
  grid-auto-flow: row;
  grid-template-rows: 2em;
  max-width: 15rem;
  margin: 1rem 0 0 0;
  padding: .5rem 2rem 1rem 2rem;
  border: .2rem ${colors.primary.v200} solid;
  background: ${colors.primary.v100};
  border-radius: 1rem;
  justify-self: center;
`;

const FormWrapper = styled.div`
  grid-area: form;
  display: grid;
  grid-template-rows: 3.5em 3.5em 10.5em;
  grid-template-columns: 1fr 12em;
  grid-template-areas: "email email"
                       "name button"
                       "question question";
  row-gap: 3em;
  column-gap: 3em;
  margin: 1em;
  z-index: 110;
  width: 70ch;
  justify-self: center;

  @media (max-width: 700px) {
    grid-template-rows: 3.5em 3.5em 10.5em 3.5em;
    grid-template-columns: 1fr 12em;
    grid-template-areas: "email email"
                        "name name"
                        "question question"
                        "blank button";
    width: 30em;
  }

  @media (max-width: 550px) {
    width: 100%;
    margin: 0;
  }
`;

const FormInput = styled.input`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};
  border: .2rem solid ${colors.primary.v200};
  background-color: ${colors.primary.v100};
  border-radius: 1rem;
  padding: .2em 1em .2em 1em;
  color: ${colors.text.o90};
  font-size: 1.6em;
  font-weight: ${fonts.condensed.regular.weight};
  font-family: ${fonts.condensed.fontFamily};
  text-transform: uppercase;

  &:hover {
    border-color: ${colors.primary.accentLight};
  }

  &:focus {
    outline: none;
    border-color: ${colors.greys.six};
  }

  &::placeholder {
    color: ${colors.greys.five};
  }
`;

const FormButton = styled.button`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};
  border: .2rem solid ${colors.primary.v400};
  background-color: ${colors.primary.v100};
  border-radius: 1rem;
  padding: .2em 1em .2em 1em;
  color: ${colors.primary.v800};
  font-size: 1.6em;
  font-weight: ${fonts.condensed.regular.weight};
  font-family: ${fonts.condensed.fontFamily};
  text-transform: uppercase;

  &:hover {
    border-color: ${colors.primary.accentLight};
    color: ${colors.primary.accentLight};
  }

  &:active {
    outline: none;
    border-color: ${colors.greys.one};
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
    border-color: ${colors.greys.one};
  }
`;

const FormTextArea = styled.textarea`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};
  border: .2rem solid ${colors.primary.v200};
  border-radius: 1rem;
  background-color: ${colors.primary.v100};
  padding: .4em 1em .2em 1em;
  color: ${colors.text.o90};
  font-size: 1.6em;
  font-weight: ${fonts.condensed.regular.weight};
  font-family: ${fonts.condensed.fontFamily};
  text-transform: uppercase;

  &:hover {
    border-color: ${colors.primary.accentLight};
  }

  &:focus {
    outline: none;
    border-color: ${colors.greys.six};
  }

  &::placeholder {
    color: ${colors.greys.five};
  }
`;

function Contact () {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState('Send!');

  function handleChange (e) {
    e.preventDefault();

    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  async function handleClick (e) {
    try {
      e.preventDefault();
      setLoading(true);
      console.log('Button Clicked!', formData);
      const { name, email, message } = formData;

      const headers = { 'Access-Control-Allow-Origin': 'https://www.heraldtx.com', 'Content-Type': 'application/json' };

      const result = await axios.post(API_URL, { name, email, content: message }, headers);
      if (result.status === 200) {
        setLoading(false);
        setButtonText('Email Sent!');
      }
    } catch (err) {
      console.error(err);
    }
  }

  console.log(loading);

  return (
    <ContactWrapper>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Get in touch with us! Contact Herald TX." />

        <meta property="og:title" content="Herald TX - Contact" />
        <meta property="og:description" content="Get in touch with us! Contact Herald TX." />
        <meta property="og:url" content="https://www.heraldtx.com/contact" />
        <meta property="og:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
        <meta property="og:image:alt" content="Herald Therapeutics Logo and Tagline: Advancing research and science for pulmonary care." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Herald Therapeutics" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.heraldtx.com/contact" />
        <meta property="twitter:title" content="Herald TX - Contact" />
        <meta property="twitter:description" content="Get in touch with us! Contact Herald TX." />
        <meta property="twitter:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
      </Helmet>
      <ContentWrapper>
        <HeadingLineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v300} strokeWidth="1.4em" />
          </svg>
        </HeadingLineWrapper>
        <Heading>Contact</Heading>
        <AddressWrapper>
          <p>Herald Therapeutics, Inc.</p>
          <p>DuPont Center</p>
          <p>9801 DuPont Ave S, Ste 408</p>
          <p>Bloomington MN 55431-3197</p>
          <p>United States</p>
          <br />
          <p>
            Office
            {' '}
            <a href="tel:+16124306222">(612) 430-6222</a>
          </p>
        </AddressWrapper>
        <FormWrapper>
          <FormInput
            gridArea="email"
            placeholder="Email"
            name="email"
            onChange={handleChange}
            value={formData.email}
          />
          <FormInput
            gridArea="name"
            placeholder="Preferred Name"
            name="name"
            onChange={handleChange}
            value={formData.name}
          />
          <FormTextArea
            gridArea="question"
            placeholder="Message"
            name="message"
            onChange={handleChange}
            value={formData.message}
          />
          {loading
            ? <LoadingSpinnerSmall />
            : (
              <FormButton gridArea="button" onClick={handleClick}>
                {buttonText}
              </FormButton>
            )}
        </FormWrapper>
      </ContentWrapper>
    </ContactWrapper>
  );
}

export default memo(Contact);
