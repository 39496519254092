import { memo } from 'react';
import styled from 'styled-components';
import { animated, useTransition, config } from 'react-spring';
import { useInView } from 'react-intersection-observer';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const BarWrapper = styled(animated.div)`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};
  justify-self: center;
  align-self: center;
  height: 2em;
  background-color: ${(props) => props.color || 'white'};
  margin: 0 0 0 0;
  border-radius: .4em;
  align-self: center;
  justify-self: left;
  z-index: ${(props) => props.zIndex || 1};
`;

const IndicationText = styled.p`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};   
  color: ${colors.text.o90};
  font-family: ${fonts.regular.fontFamily};
  font-weight: ${fonts.regular.regular.weight};
  justify-self: center;
  align-self: center;
  text-align: center;
`;

function barGraphGenerator ({
  research,
  preclinical,
  phase1,
  phase2,
  baseColor,
  gridArea = 'primary',
}) {
  const components = [];

  if (!research) return null;
  if (research) {
    components.push(
      {
        gridArea: `research-${gridArea}`,
        zIndex: 4,
        width: research,
        color: colors[baseColor].v700,
      },
    );
  }
  if (preclinical) {
    components.push(
      {
        gridArea: `preclinical-${gridArea}`,
        zIndex: 3,
        width: preclinical,
        color: colors[baseColor].v600,
      },
    );
  }
  if (phase1) {
    components.push(
      {
        gridArea: `phase-1-${gridArea}`,
        zIndex: 2,
        width: phase1,
        color: colors[baseColor].v500,
      },
    );
  }
  if (phase2) {
    components.push(
      {
        gridArea: `phase-2-${gridArea}`,
        zIndex: 1,
        width: phase2,
        color: colors[baseColor].v400,
      },
    );
  }

  return components;
}

function BigGraphBar ({
  research,
  preclinical,
  phase1,
  phase2,
  baseColor,
  gridArea,
  indication = 'yeet',
}) {
  const inViewOptions = {
    triggerOnce: true,
    rootMargin: '0px 0px 0px 0px',
    delay: 100,
  };

  const [ref, inView] = useInView(inViewOptions);

  const components = barGraphGenerator({
    research,
    preclinical,
    phase1,
    phase2,
    baseColor,
    gridArea,
  });

  const graphBar = useTransition(inView ? components : [], {
    from: { width: '0%' },
    enter: (item) => item.width,
    leave: { width: '0%' },
    trail: 400,
    config: config.default,
    delay: 100,
  });

  return (
    <>
      <IndicationText ref={ref} gridArea={`indication-${gridArea}`}>{indication}</IndicationText>
      {
        graphBar((styles, item) => (
          item
          && (
          <BarWrapper
            key={item.gridArea}
            gridArea={item.gridArea}
            zIndex={item.zIndex}
            color={item.color}
            style={styles}
          />
          )
        ))
      }
    </>

  );
}

export default memo(BigGraphBar);
