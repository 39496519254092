import PolicyContainer from './PolicyContainer';
import useTermly from './useTermly';

function Disclaimer () {
  useTermly();
  return (
    <PolicyContainer>
      <div name="termly-embed" data-id="4b7d1433-010a-46f5-9d97-1ea98087ed92" data-type="iframe" />
    </PolicyContainer>
  );
}

export default Disclaimer;
