import { useContext, memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { animated, useTransition, config } from 'react-spring';
import { Helmet } from 'react-helmet-async';

import { WindowContext } from '../../context/WindowContext';

import { ReactComponent as HomeBackground } from './home_background_cropped.svg';
import { ReactComponent as Arrow } from '../svg/Arrow.svg';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const HomeWrapper = styled.section`
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-areas: "main";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const StyledHomeBackground = styled(HomeBackground)`
  grid-area: main;
  height: 100%;
  width: 100%;
`;

const ColorHomeBackground = styled.div`
  grid-area: main;
  background-color: ${colors.primary.v500};
`;

const CTAWrapper = styled(animated.div)`
  display: grid;
  grid-area: main;
  grid-template-areas: "line" "jumbo" "cta" "button";
  grid-template-rows: 1em 10em 10em 2.5em;
  align-self: center;
  justify-self: center;
  max-width: 60em;
  overflow: hidden;

  @media (max-width: 1000px) {
    grid-template-rows: 1em 12em 10em 2.5em;
  }
`;

const JumboText = styled.h1`
  grid-area: jumbo;
  color: ${colors.greys.one};
  font-size: 3em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  max-width: 30ch;

  @media (max-width: 850px) {
    max-width: 20ch;
  }

  @media (max-width: 600px) {
    font-size: 2.5em;
    margin: 0 0 0 1rem;
  }
`;

const CTAText = styled.h2`
  grid-area: cta;
  width: 11em;
  color: ${colors.primary.v100};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 10rem;
  font-size: 1.8em;
  line-height: 1.5;

  @media (max-width: 600px) {
    font-size: 1.5em;
    margin: 0 0 0 6rem;
  }
`;

const Button = styled(Link)`
  display: grid;
  grid-area: button;
  grid-template-areas: "text icon";
  grid-template-columns: 7em 3em;
  grid-template-rows: 1fr;
  width: 10em;
  background-color: ${colors.accentTwo.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 0 0 0 10rem;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: 0 .2em 0 0;
  height: 2.5em;

  @media (max-width: 600px) {
    margin: 0 0 0 6rem;
  }

  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.secondary.v500};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonText = styled.h6`
  grid-area: text;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8em;
  color: ${colors.text.o90};
  text-align: left;
  line-height: 1;
  margin: .2em 0 0 0;
`;

const LineWrapper = styled.div`
  grid-area: line;

  @media (max-width: 600px) {
    margin: 0 0 0 1rem;
  }
`;

function Home () {
  const windowContext = useContext(WindowContext);
  const { screenWidth } = windowContext;

  const [cta, setCta] = useState([]);

  useEffect(() => {
    const ctaInitial = [
      {
        gridArea: 'line',
        component: (
          <LineWrapper>
            <svg viewBox="0 0">
              <line x="0" y1="0" x2="14em" y2="0" stroke={colors.secondary.v500} strokeWidth="1.4em" />
            </svg>
          </LineWrapper>
        ),
        height: 1,
      },
      {
        gridArea: 'jumbo',
        component: (
          <JumboText>Reimagining ARDS Treatment.</JumboText>
        ),
        height: 10,
      },
      {
        gridArea: 'cta',
        component: (
          <CTAText>
            Advancing research and science for pulmonary and cardiac care
          </CTAText>
        ),
        height: 10,
      },
      {
        gridArea: 'button',
        component: (
          <Button to="/science">
            <ButtonText>See How</ButtonText>
            <Arrow />
          </Button>
        ),
        height: 2.5,
      },
    ];

    setCta(ctaInitial);
  }, []);

  const transition = useTransition(cta, {
    from: {
      transform: `translate3d(0, 100px, 0)`,
      opacity: 0,
    },
    enter: {
      transform: `translate3d(0, 0px, 0)`,
      opacity: 1,
    },
    leave: {
      transform: `translate3d(0, 100px, 0)`,
      opacity: 0,
    },
    trail: 180,
    config: config.slow,
    delay: 500,
  });
  return (
    <HomeWrapper>
      <Helmet>
        <title>Herald Therapeutics - Advancing research and science for pulmonary care.</title>
        <meta name="description" content="Re-imagining ARDS Treatment. Herald Therapeutics, Inc (aka, HeraldTx)(HTX), is an innovative group of physicians and scientists advancing a novel treatment for Acute Respiratory Distress Syndrome (ARDS)." />

        <meta property="og:title" content="Herald Therapeutics - Advancing research and science for pulmonary and cardiac care." />
        <meta property="og:description" content="Re-imagining ARDS Treatment. Herald Therapeutics, Inc (aka, HeraldTx)(HTX), is an innovative group of physicians and scientists advancing a novel treatment for Acute Respiratory Distress Syndrome (ARDS)." />
        <meta property="og:url" content="https://www.heraldtx.com" />
        <meta property="og:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
        <meta property="og:image:alt" content="Herald Therapeutics Logo and Tagline: Advancing research and science for pulmonary and cardiac care." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Herald Therapeutics" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.heraldtx.com" />
        <meta property="twitter:title" content="Herald Therapeutics - Advancing research and science for pulmonary and cardiac care." />
        <meta property="twitter:description" content="Re-imagining ARDS Treatment. Herald Therapeutics, Inc (aka, HeraldTx)(HTX), is an innovative group of physicians and scientists advancing a novel treatment for Acute Respiratory Distress Syndrome (ARDS)." />
        <meta property="twitter:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
      </Helmet>
      {screenWidth < 1200
        ? <ColorHomeBackground />
        : <StyledHomeBackground />}
      <CTAWrapper>
        {transition((styles, item) => (
          <animated.div style={{ ...styles, gridArea: item.gridArea }}>
            {item.component}
          </animated.div>
        ))}
      </CTAWrapper>
    </HomeWrapper>
  );
}

export default memo(Home);
