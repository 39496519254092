import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

import { HTXLogo } from '../logo/HTXLogo';

const FooterWrapper = styled.footer`
  display: grid;
  background-color: ${colors.primary.v100};
  height: 22em;
  grid-template-rows: 10em 5em 5em;
  grid-template-columns: 1fr;
  grid-template-areas: "logo" "terms" "copyright";

  @media (max-width: 730px) {
    grid-template-rows: 10em auto 5em;
    padding: 0 1em 0 1em;
  }
`;

const HeraldText = styled(Link)`
  font-family: ${fonts.mono.fontFamily};
  font-style: ${fonts.mono.bold.style};
  font-weight: ${fonts.mono.bold.weight};
  font-size: 1.2em;
  align-self: center;
  color: ${colors.text.o90};
  text-decoration: none;
`;

const LogoWrapper = styled.div`
  display: grid;
  grid-area: logo;
  grid-template-columns: 4em 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "htx" "text";
  justify-self: center;
`;

const TermsWrapper = styled.div`
  display: grid;
  grid-area: terms;
  grid-template-rows: 1fr;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-self: center;
  column-gap: 2em;

  @media (max-width: 730px) {
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    grid-auto-flow: row;
    row-gap: 0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  transition: border .5s ease-in-out;
  border-radius: 1em;
  padding: 0 1em .1em 1em;
  display: flex;
  align-self: center;

  &:hover {
    background-color: ${colors.greys.three};
  }

  &:active {
    background-color: ${colors.greys.six};
  }
`;

const Description = styled.p`
  color: ${(props) => props.color || colors.text.o90};
  justify-self: center;
  align-self: center;
  margin: 0;
  text-align: center;
`;

function Footer () {
  const { pathname } = useLocation();
  return (pathname !== '/'
    && (
    <FooterWrapper>
      <LogoWrapper>
        <HTXLogo
          gridArea="htx"
          justifySelf="center"
          alignSelf="center"
          height="3em"
          width="20em"
        />
        <HeraldText to="/">herald therapeutics</HeraldText>
      </LogoWrapper>
      <TermsWrapper>
        <StyledLink to="/disclaimer">
          <Description>Disclaimer</Description>
        </StyledLink>
        <Description>|</Description>
        <StyledLink to="/terms">
          <Description>Terms and Conditions</Description>
        </StyledLink>
        <Description>|</Description>
        <StyledLink to="/privacy">
          <Description>Privacy Policy</Description>
        </StyledLink>
      </TermsWrapper>
      <Description gridArea="copyright">
        Copyright ©
        {' '}
        {new Date().getFullYear()}
        {' '}
        Herald Therapeutics, Inc. All Rights Reserved
      </Description>
    </FooterWrapper>
    )
  );
}

export default Footer;
