import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';

import { WindowContext } from '../../context/WindowContext';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

import { HTXLogo } from '../logo/HTXLogo';
import ButtonNav from './ButtonNav';
import BurgerIcon from './BurgerIcon';
import BurgerNav from './BurgerNav';

const NavWrapper = styled.nav`
  z-index: 1000;
  display: grid;
  grid-area: nav;
  grid-template-columns: 20em 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "logo nav-buttons";
  background-color: ${colors.primary.v100};
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.3);
`;

const NavLogoWrapper = styled.div`
  display: grid;
  grid-area: logo;
  grid-template-columns: 4em 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "htx" "text";
`;

const HeraldText = styled(Link)`
  font-family: ${fonts.mono.fontFamily};
  font-style: ${fonts.mono.bold.style};
  font-weight: ${fonts.mono.bold.weight};
  font-size: 1.2em;
  align-self: center;
  color: ${colors.text.o90};
  text-decoration: none;

  @media (max-width: 450px) {
    font-size: .9em;
  }
`;

function Nav () {
  const windowContext = useContext(WindowContext);
  const { screenWidth } = windowContext;

  const [isBurgerNavShowing, setIsBurgerNavShowing] = useState(false);

  function handleBurgerClick () {
    setIsBurgerNavShowing(!isBurgerNavShowing);
  }
  return (
    <NavWrapper>
      <NavLogoWrapper>
        <HTXLogo
          gridArea="htx"
          justifySelf="center"
          alignSelf="center"
          height="3em"
          width="20em"
        />
        <HeraldText to="/">herald therapeutics</HeraldText>
      </NavLogoWrapper>
      {screenWidth > 1300
        ? <ButtonNav />
        : (
          <>
            <BurgerIcon
              gridArea="nav-buttons"
              margin={screenWidth < 750 ? '1em 1.3em 0 0' : '1.5em 1.3em 0 0'}
              height="2em"
              onClick={handleBurgerClick}
              isX={isBurgerNavShowing}
              isGrey={!isBurgerNavShowing}
            />
            <BurgerNav isShowing={isBurgerNavShowing} setIsShowing={setIsBurgerNavShowing} />
          </>
        )}
    </NavWrapper>
  );
}

export default Nav;
