import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ReactComponent as Arrow } from '../svg/Arrow.svg';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const StoryWrapper = styled.section`
  display: grid;
  margin: 0 0 0 0;
  grid-template-areas: "main";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const HeadingBackground = styled.div`
  grid-area: main;
  background-color: ${colors.primary.v700};
  height: 20em;
  border-bottom: .5em solid ${colors.primary.v300};
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-area: main;
  grid-template-areas: "heading" "story" "cta";
  grid-template-columns: 1fr;
  grid-template-rows: 10em auto 10em;
  margin: 10em 1em 10em 1em;
  max-width: 80em;
  justify-self: center;
`;

const HeadingWrapper = styled.div`
  display: grid;
  grid-area: heading;
  grid-template-areas: "line" "title";
  grid-template-columns: 1fr;
  grid-template-rows: 1em 5em;
  margin: 0 0 0 -4em;

  @media (max-width: 1000px) {
    margin: 0 0 0 2rem;
  }
`;

const Heading = styled.h1`
  grid-area: title;
  color: ${colors.greys.one};
  font-size: 2em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
`;

const LineWrapper = styled.div`
  grid-area: line;
`;

const Divider = styled.div`
  margin: 4em 0 0 0;
  height: .25em;
  background-color: ${colors.primary.v300};
  align-self: center;
`;

const StoryBodyWrapper = styled.div`
  display: grid;
  grid-area: story;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  row-gap: 2em;
  justify-self: center;
  margin: 3em 0 0 0;
`;

const QuoteWrapper = styled.div`
  display: grid;
  background-color: ${colors.primary.v600};
  border-radius: 5em;
  padding: 1em 5em 1em 5em;
  max-width: 40em;
  margin: ${(props) => props.margin || '6em 0 6em 0'};

  @media (max-width: 500px) {
    padding: 1em 2em 1em 2em;
    border-radius: 3em;
  }
`;

const QuoteText = styled.h2`
  color: ${colors.primary.v100};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  justify-self: center;
  font-size: 1.8em;
  line-height: 1.5;
`;

const StoryText = styled.p`
  max-width: 70ch;
  font-size: 1em;
  justify-self: center;
  line-height: 2.2;
  margin: 0;
`;

const ButtonText = styled.h6`
  grid-area: text;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8em;
  color: ${colors.text.o90};
  text-align: left;
  line-height: 1.5;
  margin: .2em 0 0 0;
`;

const CTAWrapper = styled.div`
  display: grid;
  grid-area: cta;
  grid-template-areas: "cta-text" "button";
  grid-template-rows: 10em 2.5em;
  grid-template-columns: 1fr;
  align-self: center;
  justify-self: center;
  margin: 6em 0 0 0;
`;

const CTAText = styled.h2`
  grid-area: cta-text;
  width: 11em;
  color: ${colors.text.o90};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
`;

const Button = styled(Link)`
  display: grid;
  grid-area: button;
  grid-template-areas: "text icon";
  grid-template-columns: 7em 3em;
  grid-template-rows: 1fr;
  width: 10em;
  background-color: ${colors.accentTwo.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 0 0 0 0;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: 0 .2em 0 0;

  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.secondary.v500};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

function Story () {
  return (
    <StoryWrapper>
      <Helmet>
        <title>Story</title>
        <meta name="description" content="Herald Therapeutics, Inc (aka, HeraldTx)(HTX), is an innovative group of physicians and scientists advancing a novel treatment for Acute Respiratory Distress Syndrome (ARDS)." />

        <meta property="og:title" content="Herald TX - Story" />
        <meta property="og:description" content="Herald Therapeutics, Inc (aka, HeraldTx)(HTX), is an innovative group of physicians and scientists advancing a novel treatment for Acute Respiratory Distress Syndrome (ARDS)." />
        <meta property="og:url" content="https://www.heraldtx.com/story" />
        <meta property="og:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
        <meta property="og:image:alt" content="Herald Therapeutics Logo and Tagline: Advancing research and science for pulmonary care." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Herald Therapeutics" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.heraldtx.com/story" />
        <meta property="twitter:title" content="Herald TX - Story" />
        <meta property="twitter:description" content="Herald Therapeutics, Inc (aka, HeraldTx)(HTX), is an innovative group of physicians and scientists advancing a novel treatment for Acute Respiratory Distress Syndrome (ARDS)." />
        <meta property="twitter:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
      </Helmet>
      <HeadingBackground />
      <ContentWrapper>
        <HeadingWrapper>
          <LineWrapper>
            <svg viewBox="0 0">
              <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v400} strokeWidth="1.4em" />
            </svg>
          </LineWrapper>
          <Heading>Story</Heading>
        </HeadingWrapper>
        <StoryBodyWrapper>
          <QuoteWrapper margin="1em 0 1em 0">
            <QuoteText>ThyrOxy is &#x2018;hormone replacement for the lung&#x2019;</QuoteText>
          </QuoteWrapper>
          <StoryText>Herald Therapeutics, Inc. (HeraldTx, HTX), is an innovative group of physicians, scientists and business executives advancing a novel treatment for Acute Respiratory Distress Syndrome (ARDS) and other cardiopulmonary indications including acute Congestive Heart Failure (CHF).</StoryText>
          <StoryText>
            The name
            {' '}
            <em>HeraldTx</em>
            {' '}
            honors Dr. Sidney Harold Ingbar, a renowned leader, mentor and scientist in thyroidology. He edited the definitive text,
            {' '}
            <em>The Thyroid</em>
            , commonly known as “Ingbar&#39;s Thyroid” — first published in 1972, now in its 11th edition.
            {' '}
          </StoryText>
          <StoryText>In the 1980s, Dr. Ingbar invited his son — David Ingbar, MD, a physician-scientist and pumonologist-intensivist on the Yale faculty, to write chapters on thyroid-lung science for this text.  After initially pursuing research in other scientific areas, David published original research detailing major roles that thyroid hormones play in repair of the injured lung. These original foundational research discoveries proved conclusively that the lung with its essential thyroid hormones are critical for managing and maintaining pulmonary health: accelerating removal of excess lung fluid (pulmonary edema) in normal and injured lungs and minimizing lung inflammation and damage with oxidant injury.</StoryText>
          <StoryText>Timothy Rich, MD, entered his research training with Dr. Ingbar, also with an interest in the thyroid-lung connection, to potentially improve treatments for ARDS based on his patient care experiences.  More specifically, Tim sought to bring benchtop discoveries to the bedside to treat ARDS, given the persistent high mortality and lack of other approved therapies.  In autopsy studies of patients dying with or without ARDS, Dr. Rich demonstrated that human ARDS lungs are virtually lacking in the essential T3 thyroid hormone – due to rapid T3 destruction by increased levels of the inactivating enzyme Deiodinase 3 (DIO3, D3).</StoryText>
          <StoryText>On the clinical translation front, Robert Schumacher, PhD, having both clinical drug development and translation science expertise, initially joined the Team to complete the preclinical IND-enabling GLP toxicology studies. Based on his pharmaceutical drug development expertise, Dr. Schumacher also created ThyrOxy – a novel formulation of T3 hormone that can be safely and effectively delivered directly into the lung and airways. This effort enabled FDA approval of a Phase 1/2 human clinical trial for ARDS. Bob has also led the development of several additional ThyrOxy formulations to substantially expand the treated indication opportunities well beyond just ARDS.</StoryText>
          <QuoteWrapper>
            <QuoteText>ThyrOxy is designed to reduce lung inflammation and edema, the hallmarks of ARDS</QuoteText>
          </QuoteWrapper>
          <StoryText>Together with the exceptional Herald science team, Mr. Backstrand and Mr. McKenna bring legal and business expertise to Herald Tx.</StoryText>
          <StoryText>Drew Backstrand, JD, has extensive general counsel background, particularly in private equity (licensed in both MN and NY), offering significant corporate operations experience to include capital formation and M&A experience.   Drew ‘s corporate leadership background further compliments the Herald Team with a strong focus on optimizing operations and risk minimization, while maximizing   future business potential.  His broad corporate perspective encourages strong tactical execution, organized, and structured within the framework of Herald’s overall strategy.</StoryText>
          <StoryText>Dan McKenna, MBA, a serial entrepreneur, has led the creative expansion of Herald’s strategy beyond ARDS, to include acute Congestive Heart Failure (CHF), Infant RDS, Pneumonitis and other confidential treatment indications.   By innovatively leveraging multiple barriers to entry (regulatory market exclusivity, multi-national patent portfolios and de-risked drug approval pathways), Herald’s potential US and international business opportunity offers branded drug gross margins across multiple indications realizing projected strong financial pro forma performance.</StoryText>
          <StoryText>In summary, the private- public partnership of Herald Therapeutics and the University of Minnesota has created a compelling therapeutic opportunity for high mortality, difficult to treat illnesses, to include ARDS.  Based on more than 20 years of research at the University of Minnesota, with Herald Tx taking the project forward over the last few years, their leading therapeutic, ThyrOxy, is designed to reduce lung inflammation and edema, the hallmarks of ARDS. Starting with ground-breaking preclinical mechanistic discoveries, the research evolved to in vivo animal models, post-mortem human lung analyses, and now an active human clinical trial. After successfully completing Phase I, ThyrOxy is currently in an on-going FDA-approved Phase II Clinical Trial for all-cause ARDS; with other indications presently in the preclinical phase.</StoryText>
          <Divider />
        </StoryBodyWrapper>
        <CTAWrapper>
          <CTAText>Meet our people.</CTAText>
          <Button to="/team">
            <ButtonText>The Team</ButtonText>
            <Arrow />
          </Button>
        </CTAWrapper>
      </ContentWrapper>
    </StoryWrapper>
  );
}

export default Story;
