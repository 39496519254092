import styled, { createGlobalStyle } from 'styled-components';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import Nav from '../nav/Nav';
import Home from '../home/Home';
import Science from '../science/Science';
import Pipeline from '../pipeline/Pipeline';
import ClinicalTrial from '../clinical-trial/ClinicalTrial';
import Research from '../research/Research';
import Story from '../story/Story';
import Team from '../team/Team';
import Press from '../press/Press';
import Contact from '../contact/Contact';
import Error404 from './Error404';
import Footer from '../footer/Footer';
import Terms from '../policies/Terms';
import Privacy from '../policies/Privacy';
import Disclaimer from '../policies/Disclaimer';
import TopSideBySideCard from '../science/TopSideBySideCard';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';
import { WindowContextProvider } from '../../context/WindowContext';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    margin: 0;
    background: ${colors.primary.main};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: ${fonts.regular.fontFamily};
    font-weight: ${fonts.regular.regular.weight};
    font-style: ${fonts.regular.regular.style};
    font-size: 16px;
    background: ${colors.primary.v500};
    color: ${colors.text.o90};
  }
  #root {
    height: 100vh;
  }
`;

const AppWrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5em 1fr;
  grid-template-areas: "nav" "app";

  @media (max-width: 750px) {
    grid-template-rows: 4em 1fr;
  }
`;

const ScrollWrapper = styled.div`
  grid-area: nav / nav / app / app;
  display: grid;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: ${colors.greys.one};
  &::-webkit-scrollbar {
    display: none;
  }
`;

function ScrollToTop ({ refToUse }) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (refToUse.current) {
      // eslint-disable-next-line no-param-reassign
      refToUse.current.scrollTop = 0;
    }
  }, [pathname, refToUse]);

  return null;
}

function App () {
  const scrollContainerRef = useRef();
  return (
    <BrowserRouter>
      <WindowContextProvider>
        <HelmetProvider>
          <AppWrapper>
            <GlobalStyle />
            <Nav />
            <ScrollWrapper ref={scrollContainerRef}>
              <ScrollToTop refToUse={scrollContainerRef} />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/science" element={<Science />} />
                <Route path="/pipeline" element={<Pipeline />} />
                <Route path="/clinical-trial" element={<ClinicalTrial />} />
                <Route path="/research-ip" element={<Research />} />
                <Route path="/story" element={<Story />} />
                <Route path="/team" element={<Team />} />
                <Route path="/news" element={<Press />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/test" element={<TopSideBySideCard />} />
                <Route path="*" element={<Error404 />} />
              </Routes>
              <Footer />
            </ScrollWrapper>
          </AppWrapper>
        </HelmetProvider>
      </WindowContextProvider>
    </BrowserRouter>
  );
}

export default App;
