import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { animated, config, useTransition } from 'react-spring';
import { useInView } from 'react-intersection-observer';

import fonts from '../../styles/fonts';
import colors from '../../styles/colors';

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: "label" "bar";
  grid-template-rows: 2em 1fr;
`;

const Label = styled.h6`
  grid-area: label;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8em;
  text-transform: uppercase;
  justify-self: left;
  align-self: end;
  line-height: 2;
  margin: 1em 0 0 0;
  color: ${colors.greys.six};
`;

const BarBorder = styled.div`
  grid-area: bar;
  justify-self: center;
  align-self: center;
  height: 1em;
  margin: 0 0 0 0;
  border-radius: .4em;
  align-self: center;
  justify-self: left;
  width: 100%;
  border: .1em solid ${colors.greys.three};
  box-sizing: border-box;
  opacity: 1;
`;

const Bar = styled(animated.div)`
  grid-area: bar;
  justify-self: center;
  align-self: center;
  height: 1em;
  background-color: ${(props) => props.color || 'black'};
  margin: 0 0 0 0;
  border-radius: .4em;
  align-self: center;
  justify-self: left;
`;

function SmallGraphBar ({ title = 'title', percent, baseColor }) {
  const [color, setColor] = useState(colors.primary.v700);

  useEffect(() => {
    if (title === 'Research') return setColor(colors[baseColor].v700);
    if (title === 'Pre-Clinical') return setColor(colors[baseColor].v600);
    if (title === 'Phase 1') return setColor(colors[baseColor].v500);
    if (title === 'Phase 2') return setColor(colors[baseColor].v400);
    return setColor(colors.primary.v700);
  }, [baseColor, title]);

  const inViewOptions = {
    triggerOnce: true,
    rootMargin: '0px 0px 300px 0px',
    delay: 100,
  };

  const { ref, inView } = useInView(inViewOptions);

  const bar = useTransition(inView && percent > 0 ? percent : null, {
    from: { width: '0%' },
    enter: (item) => `${item || 0}%`,
    leave: { width: '0%' },
    trail: 400,
    config: config.default,
    delay: 100,
  });

  return (
    <Wrapper ref={ref}>
      <Label>{title}</Label>
      <BarBorder />
      {
       bar((styles, item) => (item && (
       <Bar
         color={color}
         style={styles}
       />
       )))
       }
    </Wrapper>
  );
}

export default SmallGraphBar;
