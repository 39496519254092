const colors = {
  primary: {
    v50: '#5090BD',
    v100: '#DCE9F2',
    v200: '#B9D3E5',
    v300: '#96BCD7',
    v400: '#73A6CA',
    v500: '#5090BD',
    v600: '#407397',
    v700: '#305671',
    v800: '#203A4C',
    v900: '#101D26',
  },
  secondary: {
    v50: '#FBFFFD',
    v100: '#F6FFFB',
    v200: '#EDFFF6',
    v300: '#E5FFF2',
    v400: '#DCFFED',
    v500: '#D3FFE9',
    v600: '#A9CCBA',
    v700: '#7F998C',
    v800: '#54665D',
    v900: '#2A332F',
  },
  accent: {
    v50: '#F5F2F5',
    v100: '#ECE5EB',
    v200: '#D8CAD7',
    v300: '#C5B0C3',
    v400: '#B195AF',
    v500: '#9E7B9B',
    v600: '#7E627C',
    v700: '#5F4A5D',
    v800: '#3F313E',
    v900: '#20191F',
  },
  accentTwo: {
    v50: '#F2F9F4',
    v100: '#E4F2E9',
    v200: '#C9E6D3',
    v300: '#AED9BD',
    v400: '#93CDA7',
    v500: '#78C091',
    v600: '#609A74',
    v700: '#487357',
    v800: '#304D3A',
    v900: '#18261D',
  },
  greys: {
    one: '#F2F2F3',
    two: '#D2D3D4',
    three: '#B2B3B5',
    four: '#929496',
    five: '#727477',
    six: '#525558',
    seven: '#323539',
  },
  text: { o90: 'rgba(14, 17, 22, 0.9)' },
};

export default colors;
