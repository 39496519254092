import styled from 'styled-components';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const Wrapper = styled.div`
  display: grid;
  margin: 4em 10em 0 10em;
  grid-template-areas: "oops" "jumbo";
  grid-template-columns: 1fr;
  grid-template-rows: 10em 1fr;

  @media (max-width: 800px) {
    margin: 4em 1em 5em 1em;
  }
`;

const JumboText = styled.h1`
  grid-area: jumbo;
  color: ${colors.primary.v500};
  font-size: 3em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  margin: 0;

  @media (max-width: 800px) {
    font-size: 2.5em;
  }
`;

const OopsText = styled.h2`
  grid-area: oops;
  width: 11em;
  color: ${colors.text.o90};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
  width: 20em;
  border-bottom: .2em solid ${colors.primary.v300};

  @media (max-width: 620px) {
    width: 8em;
  }
`;

function Error404 () {
  return (
    <Wrapper>
      <OopsText>404</OopsText>
      <JumboText>Darn! We can&#39;t find that page.</JumboText>
    </Wrapper>
  );
}

export default Error404;
