import { useContext } from 'react';
import styled from 'styled-components';

import { WindowContext } from '../../context/WindowContext';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

import BigGraphBar from './BigGraphBar';
import { thyroxyDirect, thyroxyInhaled, htx2 } from './pipelineData';

import { ReactComponent as ThyroxyLogo } from '../svg/thyroxy-logo-light.svg';

function generateColumn (column, gridArea) {
  return `${column}-${gridArea}`;
}

function generateRow (graph, isSmall = false) {
  const { gridArea } = graph;

  const columns = ['program', 'indication', 'research', 'preclinical', 'phase-1', 'phase-2'];

  let row;

  if (isSmall) {
    const newColumns = [...columns];
    newColumns.shift();

    row = newColumns.map((column) => generateColumn(column, gridArea));
  } else {
    row = columns.map((column) => generateColumn(column, gridArea));
  }

  return row.join(' ');
}

function generateSection (section, isSmall = false) {
  const rows = section.map(({ big }) => generateRow(big, isSmall));

  return `${rows.map((row) => `"${row}"`).join('\n')}`;
}

const GraphWrapper = styled.div`
  grid-area: graph;
  display: grid;
  grid-template-areas: "program-heading-1 indication-heading-1 research-heading-1 preclinical-heading-1 phase-1-heading-1 phase-2-heading-1"
                       "divider-1 divider-1 divider-1 divider-1 divider-1 divider-1"
                        ${generateSection(thyroxyDirect)}
                       "divider-2 divider-2 divider-2 divider-2 divider-2 divider-2"
                       ${generateSection(thyroxyInhaled)}
                       "divider-3 divider-3 divider-3 divider-3 divider-3 divider-3"
                       ${generateSection(htx2)};
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 2em .5em 1fr 1fr 1fr .5em 1fr 1fr 1fr 1fr .5em 1fr;
  background-color: ${colors.primary.v100};
  border: solid .6em ${colors.primary.v200};
  border-radius: 5em;
  padding: 1em 3em 4em 3em;
  margin: 0 0 1em 0;

  @media (max-width: 1000px) {
    grid-template-areas: 
                       "program-thyroxy-direct-ards program-thyroxy-direct-ards . . ."
                       "indication-heading-1 research-heading-1 preclinical-heading-1 phase-1-heading-1 phase-2-heading-1"
                       "divider-1 divider-1 divider-1 divider-1 divider-1"
                       ${generateSection(thyroxyDirect, true)}
                       "program-thyroxy-inhaled-chf program-thyroxy-inhaled-chf . . ."
                       "indication-heading-2 research-heading-2 preclinical-heading-2 phase-1-heading-2 phase-2-heading-2"
                       "divider-2 divider-2 divider-2 divider-2 divider-2"
                       ${generateSection(thyroxyInhaled, true)}
                       "program-htx-2-ards program-htx-2-ards . . ."
                       "indication-heading-3 research-heading-3 preclinical-heading-3 phase-1-heading-3 phase-2-heading-3"
                       "divider-3 divider-3 divider-3 divider-3 divider-3"
                       ${generateSection(htx2, true)};
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 10em 2em .5em 1fr 1fr 1fr 10em 2em .5em 1fr 1fr 1fr 1fr 10em 2em .5em 1fr;
  }
`;

const ColumnHeading = styled.h6`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8em;
  text-transform: uppercase;
  justify-self: center;
  line-height: 0;
  margin: 1em 0 0 0;
  color: ${colors.greys.six};
`;

const Divider = styled.div`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};
  background-color: ${colors.primary.v200};
`;

const ProgramWrapper = styled.div`
  grid-area: ${(props) => props.gridArea || ''};  
  display: grid;
  grid-template-areas: "program" "subtitle";
  grid-template-rows: 6em 2em;
`;

const ProgramText = styled.h3`
  grid-area: program;
  color: ${colors.text.o90};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  justify-self: center;
  margin: 0 0 0 0;
  font-size: 1.5em;
  line-height: 1.5;
  text-transform: uppercase;

  @media (max-width: 1000px) {
    justify-self: left;
    align-self: end;
    line-height: 1.5;
  }
`;

const ProgramLogoWrapper = styled.div`
  grid-area: program;
  width: 8em;
  margin: 1em 0 0 0;
  padding: 0 0 0 2em;

  @media (max-width: 1000px) {
    padding: 0 0 0 0;
  }
`;

const ProgramSubtitle = styled.h6`
  grid-area: subtitle;
  color: ${colors.primary.v800};
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.weight};
  align-self: top;
  justify-self: center;
  margin: 1em 0 0 0;
  font-size: .9em;
  line-height: 1;

  @media (max-width: 1000px) {
    justify-self: left;
  }
`;

function BigGraph () {
  const windowContext = useContext(WindowContext);
  const { screenWidth } = windowContext;

  function graphHeadings (number, showProgram) {
    const graphHeadingArray = [
      {
        text: 'Program',
        gridArea: `program-heading-${number}`,
      },
      {
        text: 'Indication',
        gridArea: `indication-heading-${number}`,
      },
      {
        text: 'Research',
        gridArea: `research-heading-${number}`,
      },
      {
        text: 'Preclinical',
        gridArea: `preclinical-heading-${number}`,
      },
      {
        text: 'Phase 1',
        gridArea: `phase-1-heading-${number}`,
      },
      {
        text: 'Phase 2',
        gridArea: `phase-2-heading-${number}`,
      },
    ];

    if (!showProgram) graphHeadingArray.shift();

    return graphHeadingArray;
  }

  return (
    <GraphWrapper>
      {graphHeadings(1, screenWidth >= 1000).map((heading) => (
        <ColumnHeading
          key={heading.gridArea}
          gridArea={heading.gridArea}
        >
          {heading.text}
        </ColumnHeading>
      ))}
      <Divider gridArea="divider-1" />
      <ProgramWrapper gridArea={screenWidth < 1000 ? 'program-thyroxy-direct-ards' : '3 / 1 / 5 / 1'}>
        <ProgramLogoWrapper>
          <ThyroxyLogo />
        </ProgramLogoWrapper>
        <ProgramSubtitle>Directly Instilled</ProgramSubtitle>
      </ProgramWrapper>
      {thyroxyDirect.map((graph) => (
        <BigGraphBar
          key={graph.big.gridArea}
          research={graph.big.research}
          preclinical={graph.big.preclinical}
          phase1={graph.big.phase1}
          phase2={graph.big.phase2}
          gridArea={graph.big.gridArea}
          baseColor={graph.baseColor}
          indication={graph.indication}
        />
      ))}
      {screenWidth < 1000
          && graphHeadings(2).map((heading) => (
            <ColumnHeading
              key={heading.gridArea}
              gridArea={heading.gridArea}
            >
              {heading.text}
            </ColumnHeading>
          ))}
      <Divider gridArea="divider-2" />
      <ProgramWrapper gridArea={screenWidth < 1000 ? 'program-thyroxy-inhaled-chf' : '6 / 1 / 9 / 1'}>
        <ProgramLogoWrapper>
          <ThyroxyLogo />
        </ProgramLogoWrapper>
        <ProgramSubtitle>Aerosolized</ProgramSubtitle>
      </ProgramWrapper>
      {thyroxyInhaled.map((graph) => (
        <BigGraphBar
          key={graph.big.gridArea}
          research={graph.big.research}
          preclinical={graph.big.preclinical}
          phase1={graph.big.phase1}
          phase2={graph.big.phase2}
          gridArea={graph.big.gridArea}
          baseColor={graph.baseColor}
          indication={graph.indication}
        />
      ))}
      {screenWidth < 1000
          && graphHeadings(3).map((heading) => (
            <ColumnHeading
              key={heading.gridArea}
              gridArea={heading.gridArea}
            >
              {heading.text}
            </ColumnHeading>
          ))}
      <Divider gridArea="divider-3" />
      <ProgramWrapper gridArea={screenWidth < 1000 ? 'program-htx-2-ards' : '11 / 1 / 14 / 1'}>
        <ProgramText>HTX-003</ProgramText>
        <ProgramSubtitle>Confidential</ProgramSubtitle>
      </ProgramWrapper>
      {htx2.map((graph) => (
        <BigGraphBar
          key={graph.big.gridArea}
          research={graph.big.research}
          preclinical={graph.big.preclinical}
          phase1={graph.big.phase1}
          phase2={graph.big.phase2}
          gridArea={graph.big.gridArea}
          baseColor={graph.baseColor}
          indication={graph.indication}
        />
      ))}
    </GraphWrapper>
  );
}

export default BigGraph;
