import styled from 'styled-components';

import { ReactComponent as LogoNoBorder } from './htx_logo_no-border.svg';

const LogoWrapper = styled.div`
  display: grid;
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};
  justify-self: ${(props) => props.justifySelf || 'center'};
  align-self: ${(props) => props.alignSelf || 'center'};
  margin: ${(props) => props.margin || '0 0 0 0'};
  opacity: ${(props) => props.opacity || 1};
  transition: opacity .5s ease-in-out;
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  z-index: ${(props) => props.zIndex};
`;

// eslint-disable-next-line import/prefer-default-export
export function HTXLogo (props) {
  const { gridArea, justifySelf, alignSelf, margin, opacity, zIndex, width, height } = props;

  return (
    <LogoWrapper
      gridArea={gridArea}
      justifySelf={justifySelf}
      alignSelf={alignSelf}
      margin={margin}
      opacity={opacity}
      zIndex={zIndex}
      height={height}
      width={width}
    >
      <LogoNoBorder style={{ justifySelf: 'center' }} />
    </LogoWrapper>
  );
}
