import PolicyContainer from './PolicyContainer';
import useTermly from './useTermly';

function Privacy () {
  useTermly();
  return (
    <PolicyContainer>
      <div name="termly-embed" data-id="e23249af-5022-4b12-bdb4-0a1cd9850ed8" data-type="iframe" />
    </PolicyContainer>
  );
}

export default Privacy;
