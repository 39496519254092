import styled from 'styled-components';
import { Link } from 'react-router-dom';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const ButtonNavWrapper = styled.div`
  display: grid;
  grid-area: nav-buttons;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  justify-self: right;
`;

const StyledLink = styled(Link)`
  display: grid;
  text-decoration: none;
  transition: border .5s ease-in-out;
  border-top: .3em solid rgba(0, 0, 0, 0);
  border-bottom: .3em solid rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0 2em 0 2em;

  &:hover {
    border-top: .3em solid rgba(0, 0, 0, 0);
    border-bottom: .3em solid ${colors.greys.five};
  }

  &:active {
    background-color: ${colors.greys.five};
  }
`;

const ButtonText = styled.h3`
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.5em;
  color: ${colors.text.o90};
  justify-self: center;
  align-self: center;
  text-transform: uppercase;
`;

function ButtonNav () {
  return (
    <ButtonNavWrapper>
      <StyledLink
        to="/science"
      >
        <ButtonText>Science</ButtonText>
      </StyledLink>
      <StyledLink
        to="/pipeline"
      >
        <ButtonText>Pipeline</ButtonText>
      </StyledLink>
      <StyledLink
        to="/clinical-trial"
      >
        <ButtonText>Clinical Trial</ButtonText>
      </StyledLink>
      <StyledLink
        to="/research-ip"
      >
        <ButtonText>Research & IP</ButtonText>
      </StyledLink>
      <StyledLink
        to="/story"
      >
        <ButtonText>Story</ButtonText>
      </StyledLink>
      <StyledLink
        to="/team"
      >
        <ButtonText>Team</ButtonText>
      </StyledLink>
      <StyledLink
        to="/news"
      >
        <ButtonText>News</ButtonText>
      </StyledLink>
      <StyledLink
        to="/contact"
      >
        <ButtonText>Contact</ButtonText>
      </StyledLink>
    </ButtonNavWrapper>
  );
}

export default ButtonNav;
