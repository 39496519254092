import { memo } from 'react';
import styled from 'styled-components';
import { animated, useSpring, config } from 'react-spring';

import colors from '../../styles/colors';

const BurgerIconWrapper = styled.div`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};
  margin: ${(props) => props.margin || '0 0 0 0'};
  height: ${(props) => props.height || '10em'};
  justify-self: right;
  z-index: 1;
`;

function BurgerIcon ({ gridArea, margin, isX, height, onClick }) {
  const transitionStyles = useSpring({
    to: {
      opacity: isX ? 0 : 1,
      y1: isX ? 9 : 1,
      y2: isX ? 1 : 9,
    },
    config: config.slow,

  });

  return (
    <BurgerIconWrapper
      gridArea={gridArea}
      margin={margin}
      height={height}
      onClick={onClick}
    >
      <svg viewBox="-1 0 11 11" width={height} height={height}>
        <animated.line x="1" y1="1" x2="9" y2={transitionStyles.y1} stroke={colors.primary.v600} strokeWidth="1" />
        <animated.line x="1" y1="5" x2="9" y2="5" stroke={colors.primary.v600} strokeWidth="1" opacity={transitionStyles.opacity} />
        <animated.line x="1" y1="9" x2="9" y2={transitionStyles.y2} stroke={colors.primary.v600} strokeWidth="1" />
      </svg>
    </BurgerIconWrapper>
  );
}

export default memo(BurgerIcon);
