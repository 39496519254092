import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ReactComponent as BackgroundOne } from './press-line-1.svg';
import { ReactComponent as BackgroundTwo } from './press-line-2.svg';
import { ReactComponent as BackgroundThree } from './press-line-3.svg';
import { ReactComponent as ExternalLinkArrow } from '../svg/external-link-icon.svg';
import { ReactComponent as Arrow } from '../svg/Arrow.svg';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const PressWrapper = styled.section`
  display: grid;
  margin: 0 0 0 0;
  grid-template-areas: "main";
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
`;

const BackgroundWrapper = styled.div`
  grid-area: main;
  overflow: hidden;
  height: 115em;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-area: main;
  grid-template-areas: "line" "heading" "press" "cta";
  grid-template-columns: 1fr;
  grid-template-rows: 2em 10em auto 10em;
  margin: 10em 10em 10em 10em;
  max-width: 900px;
  justify-self: center;

  @media (max-width: 1000px) {
    margin: 10em 5em 10em 5em;
  }

  @media (max-width: 750px) {
    margin: 10em 2em 10em 2em;
  }

  @media (max-width: 550px) {
    margin: 10em 1em 10em 1em;
  }
`;

const Heading = styled.h1`
  grid-area: heading;
  color: ${colors.primary.v700};
  font-size: 2em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  margin: 0 0 0 -8rem;

  @media (max-width: 900px) {
    margin: 0 0 0 -4rem;
  }

  @media (max-width: 750px) {
    margin: 0 0 0 0;
  }
`;

const HeadingLineWrapper = styled.div`
  grid-area: line;
  margin: 0 0 0 -8rem;

  @media (max-width: 900px) {
    margin: 0 0 0 -4rem;
  }

  @media (max-width: 750px) {
    margin: 0 0 0 0;
  }
`;

const LineWrapper = styled.div`
  grid-area: line;
  margin: 0 0 0 0;
`;

const PressItemsWrapper = styled.div`
  grid-area: press;
  display: grid;
  grid-auto-flow: row;
  row-gap: 4em;
`;

const PressItemWrapper = styled.div`
  display: grid;
  grid-template-rows: 1.5em auto .5em auto 1fr;
  grid-template-areas: "who" "title" "line" "blurb" "link";
  background-color: ${colors.primary.v100};
  border: solid .6em ${colors.primary.v200};
  border-radius: 3em;
  padding: 2em 3em 4em 3em;
  row-gap: 1em;
  max-width: 50ch;
  justify-self: center;

  @media (max-width: 450px) {
    padding: 2em 1em 4em 1em;
  }
`;

const Who = styled.h6`
  grid-area: who;
  color: ${colors.greys.five};
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.weight};
  align-self: top;
  margin: 1em 0 0 0;
  font-size: .9em;
  line-height: 1;
`;

const Title = styled.h2`
  grid-area: title;
  color: ${colors.primary.v800};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
  max-width: 30ch;
`;

const Blurb = styled.p`
  grid-area: blurb;
  line-height: 1.7;
  color: ${colors.text.o90};
  max-width: 50ch;
`;

const ExternalButton = styled.a`
  grid-area: link;
  display: grid;
  grid-template-areas: "text icon";
  grid-template-columns: 1fr 2em;
  grid-template-rows: 1fr;
  background-color: ${colors.secondary.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 1em 0 0 0;
  height: 1em;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: .9em 1.5em 1em 1.5em;
  width: 10em;
  color: ${colors.text.o90};

  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.accent.v300};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

const CTAWrapper = styled.div`
  display: grid;
  grid-area: cta;
  grid-template-areas: "cta-text" "button";
  grid-template-rows: 10em 2.5em;
  grid-template-columns: 1fr;
  align-self: center;
  justify-self: center;
  margin: 6em 0 0 0;
`;

const CTAText = styled.h2`
  grid-area: cta-text;
  width: 11em;
  color: ${colors.text.o90};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
`;

const Button = styled(Link)`
  display: grid;
  grid-area: button;
  grid-template-areas: "text icon";
  grid-template-columns: 7em 3em;
  grid-template-rows: 1fr;
  width: 10em;
  background-color: ${colors.accentTwo.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 0 0 0 0;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: 0 .2em 0 0;

  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.secondary.v500};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonText = styled.h6`
  grid-area: text;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8em;
  color: ${colors.text.o90};
  text-align: left;
  line-height: 1.5;
  margin: .2em 0 0 0;
`;

function Press () {
  return (
    <PressWrapper>
      <Helmet>
        <title>News</title>
        <meta name="description" content="See Herald TX in the news. Walleye Tank: Ice Fishing Edition competition names biomedical innovation champions" />
      </Helmet>
      <BackgroundWrapper>
        <BackgroundOne />
        <BackgroundTwo />
        <BackgroundThree />
      </BackgroundWrapper>
      <ContentWrapper>
        <HeadingLineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v300} strokeWidth="1.4em" />
          </svg>
        </HeadingLineWrapper>
        <Heading>News</Heading>
        <PressItemsWrapper>
          <PressItemWrapper>
            <Who>Dr. Ralph and Marian Falk Medical Research Trust</Who>
            <Title>Aerosolized Thyroid Hormone Treatment of Acute Lung Injury & Pulmonary Edema</Title>
            <LineWrapper>
              <svg viewBox="0 0">
                <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v600} strokeWidth=".5em" />
              </svg>
            </LineWrapper>
            <Blurb>
              Two Year Grant to Study and Develop an Aerosolized Formulation of ThyrOxy. Awarded October 2023.
            </Blurb>
          </PressItemWrapper>
          <PressItemWrapper>
            <Who>Star Tribune</Who>
            <Title>Experimental hormone therapy may speed recovery for COVID patients</Title>
            <LineWrapper>
              <svg viewBox="0 0">
                <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v600} strokeWidth=".5em" />
              </svg>
            </LineWrapper>
            <Blurb>“Duluth patients were among the first to get an experimental treatment that uses a thyroid hormone to mitigate lung failure. It&#39;s now part of a Phase 2 FDA study.”</Blurb>
            <ExternalButton
              href="https://www.startribune.com/hormone-boost-could-be-covid-19-key/572944532/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonText>Link to Article</ButtonText>
              <ExternalLinkArrow />
            </ExternalButton>
          </PressItemWrapper>
          <PressItemWrapper>
            <Who>Mayo Clinic</Who>
            <Title> &#x2018;Walleye Tank: Ice Fishing Edition&#x2019; competition names biomedical innovation champions</Title>
            <LineWrapper>
              <svg viewBox="0 0">
                <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v600} strokeWidth=".5em" />
              </svg>
            </LineWrapper>
            <Blurb>“This project, presented by David Ingbar, M.D., won in the Professionals division with the team&#39;s experimental therapy to treat acute respiratory syndrome, including COVID-induced acute respiratory syndrome, hooking a $10,000 prize.  The innovation is currently undergoing phase II clinical trials.”</Blurb>
            <ExternalButton
              href="https://advancingthescience.mayo.edu/2022/01/24/walleye-tank-winter-2021/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonText>Link to Article</ButtonText>
              <ExternalLinkArrow />
            </ExternalButton>
          </PressItemWrapper>
        </PressItemsWrapper>
        <CTAWrapper>
          <CTAText>Get in touch.</CTAText>
          <Button to="/contact">
            <ButtonText>Contact</ButtonText>
            <Arrow />
          </Button>
        </CTAWrapper>
      </ContentWrapper>
    </PressWrapper>
  );
}

export default Press;
