import styled from 'styled-components';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

import { Link } from '../ui/text';
import { ReactComponent as MailIcon } from '../svg/fi-rr-envelope.svg';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 20em 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "name-title bio";
  column-gap: 2em;
  padding: 1em;
  margin: 0;

  @media (max-width: 1000px) {
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: "name-title" "bio";
    row-gap: 2em;
    margin: -3em 0 0 0;
  }

  @media (max-width: 400px) {
    padding: 0;
  }
`;

const NameTitleWrapper = styled.div`
  grid-area: name-title;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2em 3em 4em 20em;
  grid-template-areas: "line" "name" "title" "picture" "contact";
  margin: 0;

  @media (max-width: 1000px) {
    grid-template-columns: 20em;
    margin: 0 0 0 6em;
  }

  @media (max-width: 600px) {
    margin: 0 0 0 2em;
  }

  @media (max-width: 500px) {
    margin: 0 0 0 0;
  }

  @media (max-width: 400px) {
    grid-template-columns: 15em;
    grid-template-rows: 2em 3em 4em 15em;
  }
`;

const TeamName = styled.h2`
  grid-area: name;
  color: ${colors.greys.one};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
`;

const TeamTitle = styled.h6`
  grid-area: title;
  color: ${colors.primary.v100};
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.weight};
  align-self: top;
  margin: 1em 0 0 0;
  font-size: .9em;
  line-height: 1;
`;

const TeamContactWrapper = styled.div`
  grid-area: contact;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
`;

const TeamContactLine = styled.div`
  display: grid;
  grid-template-areas: "icon link";
  grid-template-columns: 3rem 1fr;
  padding: 0 0 0 1rem;
  margin: 2rem 0 0 0;
`;

const IconWrapper = styled.div`
  grid-area: icon;
`;

const TeamImgWrapper = styled.div`
  grid-area: picture;
  display: grid;
  grid-template-areas: "main";
  border: .5em solid ${colors.primary.v500};
  border-radius: 2em;
  overflow: hidden;
`;

const TeamImg = styled.img`
  grid-area: main;
  width: 100%;
`;

const LineWrapper = styled.div`
  grid-area: line;
  margin: 0 0 0 0;
`;

const BioWrapper = styled.div`
  grid-area: bio;
  background-color: ${colors.greys.one};
  border-radius: 2em;
  padding: 2em;

  @media (max-width: 500px) {
    padding: 1em;
  }
`;

const BioLi = styled.li`
  line-height: 1.7;
  margin: 0 0 .25em 0;
  color: ${colors.text.o90};
`;

const BioTitle = styled.p`
  line-height: 1.7;
  color: ${colors.text.o90};
`;

function TeamModal ({ name, title, imgSrc, bio, email }) {
  return (
    <Wrapper>
      <NameTitleWrapper>
        <LineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v400} strokeWidth="1.4em" />
          </svg>
        </LineWrapper>
        <TeamName>{name}</TeamName>
        <TeamTitle>{title}</TeamTitle>
        <TeamImgWrapper>
          <TeamImg src={imgSrc} alt={name} />
        </TeamImgWrapper>
        <TeamContactWrapper>
          <TeamContactLine>
            <IconWrapper>
              <MailIcon />
            </IconWrapper>
            <Link
              gridArea="link"
              href={`mailto:${email}`}
              color={colors.greys.one}
              justifySelf="left"
            >
              {email}
            </Link>
          </TeamContactLine>
        </TeamContactWrapper>
      </NameTitleWrapper>
      <BioWrapper>
        {bio.map((bioItem) => (
          <div key={`container-${bioItem.title}`}>
            <BioTitle key={bioItem.title}><strong key={`strong-${bioItem.title}`}>{bioItem.title}</strong></BioTitle>
            <ul key={`list-${bioItem.title}`}>
              {bioItem.items.map((item) => <BioLi key={`item-${item}`}>{item}</BioLi>)}
            </ul>
          </div>
        ))}
      </BioWrapper>
    </Wrapper>
  );
}

export default TeamModal;
