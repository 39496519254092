import { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { WindowContext } from '../../context/WindowContext';

import { ReactComponent as Arrow } from '../svg/Arrow.svg';
import { ReactComponent as BackgroundOne } from './pipeline-line-1.svg';
import { ReactComponent as BackgroundTwo } from './pipeline-line-2.svg';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

import BigGraph from './BigGraph';
import SmallGraph from './SmallGraph';

const PipelineWrapper = styled.section`
  display: grid;
  margin: 0 0 0 0;
  grid-template-areas: "main";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const BackgroundWrapper = styled.div`
  grid-area: main;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-area: main;
  grid-template-areas: "line" "heading" "description" "graph" "disclaimer" "cta";
  grid-template-columns: 1fr;
  grid-template-rows: 2em 8em 20em 50em auto 10em;
  margin: 10em 10em 10em 10em;

  @media (max-width: 1600px) {
    margin: 10em 1em 10em 1em;
  }

  @media (max-width: 1000px) {
    grid-template-rows: 2em 8em 20em auto auto 10em;
  }

  @media (max-width: 650px) {
    grid-template-rows: 2em 8em auto auto auto 10em;
  }
`;

const Heading = styled.h1`
  grid-area: heading;
  color: ${colors.primary.v700};
  font-size: 2em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  margin: 0 0 0 8rem;

  @media (max-width: 900px) {
    margin: 0 0 0 1rem;
  }
`;

const Description = styled.p`
  grid-area: ${(props) => props.gridArea || 'description'};
  line-height: 2;
  color: ${(props) => props.color || colors.text.o90};
  justify-self: ${(props) => props.justifySelf || 'left'};
  max-width: 60ch;
  margin: 0 0 5em 8rem;
  background-color: ${colors.primary.v100};
  padding: 2em;
  border-radius: 3em;
  border: solid .6em ${colors.primary.v200};
  border-radius: 3em;

  @media (max-width: 900px) {
    margin: 0 0 5em 1rem;
  }

  @media (max-width: 650px) {
    margin: 0 0 5em 0;
  }
`;

const Disclaimer = styled.p`
  grid-area: ${(props) => props.gridArea || 'description'};
  line-height: 2;
  color: ${colors.greys.one};
  justify-self: ${(props) => props.justifySelf || 'left'};
  max-width: 60ch;
  margin: 1em 0 5em 0;
  background-color: ${colors.primary.v800};
  padding: 2em;
  border-radius: 3em;
  border: solid .4em ${colors.primary.v700};
  border-radius: 3em;
  font-size: .8em;

  @media (max-width: 900px) {
    margin: 1em 0 5em 0;
  }

  @media (max-width: 650px) {
    margin: 1em 0 5em 0;
  }
`;

const LineWrapper = styled.div`
  grid-area: line;
  margin: 0 0 0 8rem;

  @media (max-width: 900px) {
    margin: 0 0 0 1rem;
  }
`;

const Button = styled(Link)`
  display: grid;
  grid-area: button;
  grid-template-areas: "text icon";
  grid-template-columns: 7em 3em;
  grid-template-rows: 1fr;
  width: 10em;
  background-color: ${colors.accentTwo.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 0 0 0 0;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: 0 .2em 0 .5em;

  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.secondary.v500};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonText = styled.h6`
  grid-area: text;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8em;
  color: ${colors.text.o90};
  text-align: left;
  line-height: 1;
  margin: .2em 0 0 0;
`;

const CTAWrapper = styled.div`
  display: grid;
  grid-area: cta;
  grid-template-areas: "cta-text" "button";
  grid-template-rows: 10em 2.5em;
  grid-template-columns: 1fr;
  align-self: center;
  justify-self: center;
`;

const CTAText = styled.h2`
  grid-area: cta-text;
  width: 11em;
  color: ${colors.text.o90};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
`;

function Pipeline () {
  const windowContext = useContext(WindowContext);
  const { screenWidth } = windowContext;

  return (
    <PipelineWrapper>
      <Helmet>
        <title>Pipeline</title>
        <meta name="description" content="Herald Therapeutics lead product candidate is ThyrOxy, directly instilled T3 for patients with moderate to severe ARDS who are already intubated. In order to maximize T3’s potential we are also developing aerosol delivery formulations and compounds that will expand the use of T3 to all stages of ARDS as well as to other indications." />

        <meta property="og:title" content="Herald TX - Pipeline" />
        <meta property="og:description" content="Herald's lead product candidate is ThyrOxy, directly instilled T3 for patients with moderate to severe ARDS who are already intubated. In order to maximize T3’s potential we are also developing aerosol delivery formulations and compounds that will expand the use of T3 to all stages of ARDS as well as to other indications." />
        <meta property="og:url" content="https://www.heraldtx.com/pipeline" />
        <meta property="og:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
        <meta property="og:image:alt" content="Herald Therapeutics Logo and Tagline: Advancing research and science for pulmonary care." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Herald Therapeutics" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.heraldtx.com/pipeline" />
        <meta property="twitter:title" content="Herald TX - Pipeline" />
        <meta property="twitter:description" content="Herald's lead product candidate is ThyrOxy, directly instilled T3 for patients with moderate to severe ARDS who are already intubated. In order to maximize T3’s potential we are also developing aerosol delivery formulations and compounds that will expand the use of T3 to all stages of ARDS as well as to other indications." />
        <meta property="twitter:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
      </Helmet>
      <BackgroundWrapper>
        <BackgroundOne />
        <BackgroundTwo />
      </BackgroundWrapper>
      <ContentWrapper>
        <LineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v300} strokeWidth="1.4em" />
          </svg>
        </LineWrapper>
        <Heading>Pipeline</Heading>
        <Description>
          Herald Therapeutics lead product candidate is ThyrOxy, directly instilled T3 for patients with moderate to severe ARDS who are already intubated. In order to maximize T3’s potential we are also developing aerosol delivery formulations and compounds that will expand the use of T3 to all stages of ARDS as well as to other indications.
        </Description>
        {screenWidth < 750 ? <SmallGraph /> : <BigGraph />}
        <Disclaimer gridArea="disclaimer">
          <strong>DISCLAIMER</strong>
          <br />
          THYROXY AND OTHER SELECT HTX THERAPIES ARE INVESTIGATIONAL DRUG CANDIDATES AND HAVE NOT BEEN APPROVED FOR MARKETING BY THE FDA OR ANY OTHER REGULATORY AGENCIES
        </Disclaimer>
        <CTAWrapper>
          <CTAText>See our active trials.</CTAText>
          <Button to="/clinical-trial">
            <ButtonText>Clinical Trial</ButtonText>
            <Arrow />
          </Button>
        </CTAWrapper>
      </ContentWrapper>
    </PipelineWrapper>
  );
}

export default Pipeline;
