import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { ReactComponent as Arrow } from '../svg/Arrow.svg';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

import useModal from '../modal/useModal';

import Modal from '../modal/Modal';
import TeamCard from './TeamCard';
import TeamModal from './TeamModal';

const TeamWrapper = styled.section`
  display: grid;
  margin: 0 0 0 0;
  grid-template-areas: "main";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const BackgroundWrapper = styled.div`
  grid-area: main;
  background-color: ${colors.primary.v700};
  height: 20em;
  border-bottom: .5em solid ${colors.primary.v300};
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-area: main;
  grid-template-areas: "line" "heading" "team" "cta";
  grid-template-columns: 1fr;
  grid-template-rows: 2em 10em auto 10em;
  margin: 10em 10em 10em 10em;

  @media (max-width: 1400px) {
    margin: 10em 5em 10em 5em;
  }

  @media (max-width: 850px) {
    margin: 10em 2em 10em 2em;
  }
`;

const Heading = styled.h1`
  grid-area: heading;
  color: ${colors.primary.v100};
  font-size: 2em;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  margin: 0 0 0 8rem;

  @media (max-width: 1000px) {
    margin: 0 0 0 4rem;
  }

  @media (max-width: 850px) {
    margin: 0 0 0 1rem;
  }
`;

const LineWrapper = styled.div`
  grid-area: line;
  margin: 0 0 0 8rem;

  @media (max-width: 1000px) {
    margin: 0 0 0 4rem;
  }

  @media (max-width: 850px) {
    margin: 0 0 0 1rem;
  }

  @media (max-width: 450px) {
    margin: 0 0 0 0;
  }
`;

const TeamCardsWrapper = styled.div`
  display: grid;
  grid-auto-rows: 20em;
  grid-template-columns: repeat(3, 20em);
  row-gap: 2em;
  column-gap: 2em;
  justify-self: center;

  @media (max-width: 1250px) {
    grid-template-columns: repeat(2, 20em);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 20em);
  }
`;

const CTAWrapper = styled.div`
  display: grid;
  grid-area: cta;
  grid-template-areas: "cta-text" "button";
  grid-template-rows: 10em 2.5em;
  grid-template-columns: 1fr;
  align-self: center;
  justify-self: center;
  margin: 6em 0 0 0;
`;

const CTAText = styled.h2`
  grid-area: cta-text;
  width: 11em;
  color: ${colors.text.o90};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 1.8em;
  line-height: 1.5;
`;

const Button = styled(Link)`
  display: grid;
  grid-area: button;
  grid-template-areas: "text icon";
  grid-template-columns: 7em 3em;
  grid-template-rows: 1fr;
  width: 10em;
  background-color: ${colors.accentTwo.v500};
  border: none;
  box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.1);
  margin: 0 0 0 0;
  transition: background-color .5s;
  text-decoration: none;
  place-items: center;
  padding: 0 .2em 0 0;

  &:hover {
    color: ${colors.accentTwo.v900};
    background-color: ${colors.secondary.v500};
    box-shadow: 4px 4px 12px 0px rgba(0,0,0,0.2);
  }

  &:active {
    outline: none;
    color: ${colors.greys.five};
    background-color: ${colors.greys.one};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonText = styled.h6`
  grid-area: text;
  font-family: ${fonts.mono.fontFamily};
  font-weight: ${fonts.mono.bold.weight};
  font-size: .8em;
  color: ${colors.text.o90};
  text-align: left;
  line-height: 1.5;
  margin: .2em 0 0 0;
`;

function Team () {
  const { isShowing, toggleModal } = useModal();

  const [modalChildren, setModalChildren] = useState(null);
  // const [team, setTeam] = useState([]);

  const team = [
    {
      imgSrc: 'https://htx-images.s3.amazonaws.com/david-ingbar-grey-background.png',
      name: 'David H. Ingbar, MD',
      title: 'Chief Scientific Officer',
      onClick: () => {
        setModalChildren(
          <TeamModal
            name="David H. Ingbar, MD"
            title="Chief Scientific Officer"
            imgSrc="https://htx-images.s3.amazonaws.com/david-ingbar.png"
            email="dingbar@heraldtx.com"
            bio={[
              {
                title: 'Dr. Ingbar - born and raised in Boston, MA',
                items: [
                  'BA Chemistry, Reed College, Portland, OR',
                  'First thyroid publication 1974',
                  'MD, Harvard Medical School',
                  'Residency & Chief Resident in Internal Medicine, Univ of Washington',
                  'Fellow in Pulmonary & Critical Care Medicine, Yale University',
                  'Assistant Professor, Medicine and Cell Biology, Yale University',
                ],
              },
              {
                title: 'At UMN since 1991, Professor, Medicine, Pediatrics & Physiology',
                items: [
                  'Past Director, Pulmonary, Allergy, Critical Care & Sleep Medicine, 2001 - 2023',
                  'Past President, American Thoracic Society (ATS)',
                  'Associate Director, UMN Clinical & Translational Science Institute',
                  'Frequent NIH grant reviewer',
                  'Member NHLBI Council of Scientific Advisors 2020 - present',
                  'More than 225 publications',
                  'First in the world to instill human recombinant surfactant into patient with ARDS',
                ],
              },
            ]}
          />,
        );
        toggleModal();
      },
    },
    {
      imgSrc: 'https://htx-images.s3.amazonaws.com/tim-rich-grey-background.png',
      name: 'Timothy P. Rich, MD',
      title: 'Chief Medical Officer',
      onClick: () => {
        setModalChildren(
          <TeamModal
            name="Timothy P. Rich, MD"
            title="Chief Medical Officer"
            imgSrc="https://htx-images.s3.amazonaws.com/tim-rich.png"
            email="trich@heraldtx.com"
            bio={[
              {
                title: 'Dr. Rich - born and raised in Northern Wisconsin on a sheep farm',
                items: [
                  'BS Biology/BA Psychology, UMN Duluth',
                  'MD, UMN Medical School',
                  'Residency in Internal Medicine, UMN Minneapolis',
                  'Fellowship in Pulmonary, Critical Care & Sleep, UMN Minneapolis',
                ],
              },
              {
                title: 'At Essentia Health (Duluth, MN) since 2007',
                items: [
                  'Holds clinical, academic, research, and leadership positions and has received numerous honors',
                  'Past Chair of Pulmonary, Critical Care and Sleep Medicine',
                  'Several pending publications on his work on Acute Respiratory Distress Syndrome (ARDS), collaborating with Dr. Ingbar',
                  'Principal Investigator for first-in-human instillation of thyroid hormone into lungs of a patient with ARDS',
                ],
              },
            ]}
          />,
        );
        toggleModal();
      },
    },
    {
      imgSrc: 'https://htx-images.s3.amazonaws.com/bob-shumacher-grey-background.png',
      name: 'Robert Schumacher, PhD',
      title: 'Chief Development Officer',
      onClick: () => {
        setModalChildren(
          <TeamModal
            name="Robert Schumacher, PhD"
            title="Chief Development Officer"
            imgSrc="https://htx-images.s3.amazonaws.com/bob-shumacher.png"
            email="rschumacher@heraldtx.com"
            bio={[
              {
                title: 'Dr. Schumacher - Born and raised in southern Minnesota on a farm',
                items: [
                  'BS in Chemistry, St. Cloud State University ',
                  'PhD in Biochemistry, Mayo Graduate School',
                  'Fellowship at Yale University School of Medicine',
                  'Assistant Professor, Laboratory Medicine and Pathology, Mayo Clinic',
                  'R&D leadership positions in pharma and at Mayo Clinic',
                  'Scientific Director, UMN Center for Translational Medicine (since 2009)',
                ],
              },
              {
                title: 'Dr. Schumacher has more than 20 years of experience across all aspects of drug development; from strategy, translational research and preclinical development, to regulatory, clinical trial design and implementation',
                items: [
                  'Designed and led preclinical and clinical development programs for NCE and repurposed drug products',
                  'Directed R&D programs to advance multiple therapeutic candidates into clinical development',
                  'Developed INDs and strategies for End-of-Phase 2 meetings',
                  'Helped secure more than $20M to translate therapeutic candidates',
                ],
              },
            ]}
          />,
        );
        toggleModal();
      },
    },
    {
      imgSrc: 'https://htx-images.s3.amazonaws.com/drew-backstrand-grey-background.png',
      name: 'Drew Backstrand, Esq',
      title: 'General Counsel',
      onClick: () => {
        setModalChildren(
          <TeamModal
            name="Drew Backstrand, Esq"
            title="General Counsel"
            imgSrc="https://htx-images.s3.amazonaws.com/drew-backstrand.png"
            email="dbackstrand@heraldtx.com"
            bio={[
              {
                title: 'Mr. Backstrand - a Minnesota native',
                items: [
                  'BS, Political Science, Carleton College',
                  'JD, University of Minnesota Law School',
                  'Licensed to practice law in the MN Federal District Court, the 8th Circuit Court of Appeals and all MN state Courts.  Also Licensed in the state of NY and licensed to practice before the United States Supreme Court',
                  'Past Board Member, President, Susan G. Komen Breast Cancer Foundation, MN Affiliate',
                ],
              },
              {
                title: 'Mr. Backstrand - 10 years as a prior corporate General Counsel for private equity firm with significant leadership for legal issues concerning corporate finance, risk management and operations in the US and internationally',
                items: [
                  'Former associate and general counsel for NYSE finance company Green Tree Financial',
                  'Forty years combined private equity and SEC regulatory experience',
                  'Raised funds or worked on transactions involving up to $250M in raised capital with positions in five public and private entities',
                ],
              },
            ]}
          />,
        );
        toggleModal();
      },
    },
    {
      imgSrc: 'https://htx-images.s3.amazonaws.com/dan-mckenna-grey-background.png',
      name: 'Daniel B. McKenna',
      title: 'Chief Executive Officer',
      onClick: () => {
        setModalChildren(
          <TeamModal
            name="Daniel B. McKenna"
            title="Chief Executive Officer"
            imgSrc="https://htx-images.s3.amazonaws.com/dan-mckenna.png"
            email="dmckenna@heraldtx.com"
            bio={[
              {
                title: 'Mr. McKenna - born in ND, raised in many states, finished in MN',
                items: [
                  'BSEE, North Dakota State University',
                  'MSEE Coursework University of Colorado, Electromagnetics',
                  'MBA, University of Colorado',
                  'Registered Professional Engineer, Colorado',
                  'Lead Inventor, 57 unique US patents (issued); many are international',
                ],
              },
              {
                title: 'Mr. McKenna - senior executive with 30 years of C level experience ',
                items: [
                  'Multiple successful start-ups with exits, including combination drug/device for bladder cancer (co-founder)',
                  'As CEO/Chairman, started and sold Vesuvius to Qualcomm',
                  'Held executive officer and board level positions in public, private and nonprofit organizations',
                  'Led visionary integration of science/technology/business elements into a coherent executable strategy',
                  'Understand complexities, cultures of start-ups while managing emerging company issues with novel solutions',
                ],
              },
            ]}
          />,
        );
        toggleModal();
      },
    },
  ];

  return (
    <TeamWrapper>
      <Helmet>
        <title>Team</title>
        <meta name="description" content="Meet the HTX Team!" />

        <meta property="og:title" content="Herald TX - Team" />
        <meta property="og:description" content="Herald Therapeutics, Inc (aka, HeraldTx)(HTX), is an innovative group of physicians and scientists" />
        <meta property="og:url" content="https://www.heraldtx.com/team" />
        <meta property="og:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
        <meta property="og:image:alt" content="Herald Therapeutics Logo and Tagline: Advancing research and science for pulmonary care." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Herald Therapeutics" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.heraldtx.com/team" />
        <meta property="twitter:title" content="Herald TX - Team" />
        <meta property="twitter:description" content="Herald Therapeutics, Inc (aka, HeraldTx)(HTX), is an innovative group of physicians and scientists" />
        <meta property="twitter:image" content="https://htx-images.s3.amazonaws.com/htx-open-graph.png" />
      </Helmet>
      <BackgroundWrapper />
      <ContentWrapper>
        <LineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="14em" y2="0" stroke={colors.primary.v400} strokeWidth="1.4em" />
          </svg>
        </LineWrapper>
        <Heading>Team</Heading>
        <TeamCardsWrapper>
          {team.map((teamMember) => (
            <TeamCard
              key={teamMember.imgSrc}
              imgSrc={teamMember.imgSrc}
              name={teamMember.name}
              title={teamMember.title}
              onClick={teamMember.onClick}
            />
          ))}
        </TeamCardsWrapper>
        <CTAWrapper>
          <CTAText>Find out what people are saying.</CTAText>
          <Button to="/news">
            <ButtonText>News</ButtonText>
            <Arrow />
          </Button>
        </CTAWrapper>
      </ContentWrapper>
      <Modal isShowing={isShowing} hide={toggleModal} displayElem={modalChildren} />
    </TeamWrapper>
  );
}

export default Team;
