import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { animated, useTransition, config, useSpringRef, useChain } from 'react-spring';
import { Link } from 'react-router-dom';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

const BurgerNavWrapper = styled(animated.div)`
  height: 100vh;
  width: 100vw;
  background-color: ${colors.primary.v100};
  position: fixed;
  will-change: opacity transform;
  display: grid;
  grid-auto-rows: 5em;
  grid-template-columns: 1fr;
  margin: 0 0 0 0;
  padding: 5em 0 0 0;
`;

const StyledLink = styled(Link)`
  display: grid;
  text-decoration: none;
  transition: border .5s ease-in-out;
  border-top: .3em solid rgba(0, 0, 0, 0);
  border-bottom: .3em solid rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0 2em 0 2em;

  &:hover {
    border-top: .3em solid rgba(0, 0, 0, 0);
    border-bottom: .3em solid ${colors.greys.five};
  }

  &:active {
    background-color: ${colors.greys.five};
  }
`;

const ButtonText = styled.h3`
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  margin: 0 0 0 0;
  font-size: 3em;
  color: ${colors.text.o90};
  justify-self: center;
  align-self: center;
  text-transform: uppercase;
`;

const AnimatedDiv = styled(animated.div)`
  display: grid;
`;

function BurgerNav ({ isShowing, setIsShowing }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const dataInit = [
      {
        to: '/science',
        text: 'Science',
        onClick: () => {
          setIsShowing(false);
        },
      },
      {
        to: '/pipeline',
        text: 'Pipeline',
        onClick: () => {
          setIsShowing(false);
        },
      },
      {
        to: '/clinical-trial',
        text: 'Clinical Trial',
        onClick: () => {
          setIsShowing(false);
        },
      },
      {
        to: '/research-ip',
        text: 'Research & IP',
        onClick: () => {
          setIsShowing(false);
        },
      },
      {
        to: '/story',
        text: 'Story',
        onClick: () => {
          setIsShowing(false);
        },
      },
      {
        to: '/team',
        text: 'Team',
        onClick: () => {
          setIsShowing(false);
        },
      },
      {
        to: '/news',
        text: 'News',
        onClick: () => {
          setIsShowing(false);
        },
      },
      {
        to: '/contact',
        text: 'Contact',
        onClick: () => {
          setIsShowing(false);
        },
      },
    ];

    setData(dataInit);
  }, [setIsShowing]);

  const transitionsApi = useSpringRef();
  const transitions = useTransition(isShowing, {
    ref: transitionsApi,
    from: { transform: `translate3d(0, -100%, 0)` },
    enter: { transform: `translate3d(0, 0%, 0)` },
    leave: { transform: `translate3d(0, -105%, 0)` },
    reverse: true,
    config: {
      precision: 0.01,
      friction: 35,
    },
  });

  const linksApi = useSpringRef();
  const links = useTransition(isShowing ? data : [], {
    ref: linksApi,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    trail: 200 / data.length,
    config: config.default,
    delay: 100,
  });

  useChain(isShowing ? [transitionsApi, linksApi] : [linksApi, transitionsApi], [0, 0.7]);

  return transitions((styles, item) => item && (
    <BurgerNavWrapper style={styles}>
      {links((style, i) => (
        <AnimatedDiv style={style}>
          <StyledLink to={i.to} onClick={i.onClick}>
            <ButtonText>{i.text}</ButtonText>
          </StyledLink>
        </AnimatedDiv>
      ))}
    </BurgerNavWrapper>
  ));
}

export default BurgerNav;
