import styled from 'styled-components';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

import SmallGraphIndicationCard from './SmallGraphIndicationCard';
import { thyroxyDirect, thyroxyInhaled, htx2 } from './pipelineData';

const SmallGraphWrapper = styled.div`
  grid-area: graph;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  row-gap: 4em;
`;

const ProgramCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 2em repeat(auto-fill, 1fr);
  grid-auto-flow: row;
  row-gap: 2em;
  background-color: ${colors.primary.v100};
  border: solid .6em ${colors.primary.v200};
  border-radius: 3em;
  padding: 2em 2em 2em 2em;
  margin: 0 10em 0 10em;

  @media (max-width: 800px) {
    margin: 0 5em 0 5em;
  }

  @media (max-width: 675px) {
    margin: 0 2em 0 2em;
  }

  @media (max-width: 525px) {
    margin: 0 1em 0 1em;
  }
  @media (max-width: 450px) {
    margin: 0 0 0 0;
  }
`;

const ProgramText = styled.h3`
  grid-area: ${(props) => props.gridArea || '1 / 1 / 1 / 1'};  
  color: ${colors.text.o90};
  font-family: ${fonts.condensed.fontFamily};
  font-weight: ${fonts.condensed.regular.weight};
  align-self: center;
  justify-self: left;
  margin: 0 0 0 0;
  font-size: 1.5em;
  line-height: 1.5;
  text-transform: uppercase;
`;

function SmallGraph () {
  return (
    <SmallGraphWrapper>
      <ProgramCard>
        <ProgramText>ThyrOxy</ProgramText>
        {thyroxyDirect.map((card) => (
          <SmallGraphIndicationCard
            key={card.indication}
            indication={card.indication}
            stage={card.stage}
            percent={card.small.percent}
            baseColor={card.baseColor}
            bars={card.small.bars}
          />
        ))}
      </ProgramCard>
      <ProgramCard>
        <ProgramText>HTX-003</ProgramText>
        {thyroxyInhaled.map((card) => (
          <SmallGraphIndicationCard
            key={card.indication}
            indication={card.indication}
            stage={card.stage}
            percent={card.small.percent}
            baseColor={card.baseColor}
            bars={card.small.bars}
          />
        ))}
      </ProgramCard>
      <ProgramCard>
        <ProgramText>HTX-003</ProgramText>
        {htx2.map((card) => (
          <SmallGraphIndicationCard
            key={card.indication}
            indication={card.indication}
            stage={card.stage}
            percent={card.small.percent}
            baseColor={card.baseColor}
            bars={card.small.bars}
          />
        ))}
      </ProgramCard>
    </SmallGraphWrapper>
  );
}

export default SmallGraph;
